import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Input, Label } from "semantic-ui-react";

import { argMax } from "../../../utils/analysis_helper";

import OutcomeLabel from "./Label";
import OutcomeDetail from "./Detail";

class OutcomeResult extends PureComponent {
  render() {
    const { data, label, labels, threshold, title, type, units, value, values, single, ...props } = this.props,
      ResultBoolean = () => {
        const index = argMax(values);
        return <OutcomeLabel className="OutcomeResult OutcomeResult-boolean" index={index} labels={labels} units={units} {...props} />;
      },
      ResultDichotomous = () => {
        const index = Number(values[1] > threshold),
          trigger = <OutcomeLabel className="OutcomeResult OutcomeResult-dichotomous" basic={true} index={index} labels={labels} {...props} />;
        return <OutcomeDetail title={title} trigger={trigger} threshold={threshold} labels={labels} values={values} />;
      },
      ResultOrdinal = () => {
        const index = argMax(values),
          trigger = <OutcomeLabel className="OutcomeResult OutcomeResult-ordinal" basic={true} index={index} labels={labels} {...props} />;
        return <OutcomeDetail title={title} trigger={trigger} labels={labels} values={values} />;
      };
    switch (type) {
      case "Boolean":
        return <ResultBoolean />;
      case "Dichotomous":
        return <ResultDichotomous />;
      case "Ordinal":
        return <ResultOrdinal />;
      case "Continuous":
      case "ContinuousGT":
      case "ContinuousLT":
      case "Count":
        const className = classNames("OutcomeResult", "OutcomeResult-values", {
          "has-value": value !== 0,
        });
        return (
          <Input className={className} size="mini" value={value} fluid={true} disabled={true} labelPosition={units ? "right" : "left"}>
            {single ? null : <Label content={label} />}
            <input />
            {units && <Label content={units} />}
          </Input>
        );
      default:
        return <pre>{JSON.stringify(this.props, null, 2)}</pre>;
    }
  }
}

OutcomeResult.propTypes = {
  id: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  type: PropTypes.string.isRequired,
};

export default OutcomeResult;
