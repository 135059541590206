import { ENV } from "../config.json";

export const APP_ENV = process.env.REACT_APP_ENV,
  API_URL =
    (APP_ENV && ENV[APP_ENV]["URL_API"]) || ENV["production"]["URL_API"],
  SITE_URL =
    (APP_ENV && ENV[APP_ENV]["URL_SITE"]) || ENV["production"]["URL_SITE"],
  URL_CYBERSIGHT_PATIENT_CASE =
    (APP_ENV && ENV[APP_ENV]["URL_CYBERSIGHT_PATIENT_CASE"]) ||
    ENV["production"]["URL_CYBERSIGHT_PATIENT_CASE"],
  URL_CYBERSIGHT_SLO =
    (APP_ENV && ENV[APP_ENV]["URL_CYBERSIGHT_SLO"]) ||
    ENV["production"]["URL_CYBERSIGHT_SLO"],
  CONSULT_WORKSPACE_ID =
    (APP_ENV && ENV[APP_ENV]["CONSULT_WORKSPACE_ID"]) ||
    ENV["production"]["CONSULT_WORKSPACE_ID"],
  CONSULT_INTEGRATION_TOKEN =
    (APP_ENV && ENV[APP_ENV]["CONSULT_INTEGRATION_TOKEN"]) ||
    ENV["production"]["CONSULT_INTEGRATION_TOKEN"];
