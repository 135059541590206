import React, { PureComponent } from "react";

import "./index.scss";

// import { fileShape } from "../../../types/file";
import { getGroups } from "../../../utils/analysis_helper";
import TableGroup from "./Group";

class AnalysisTable extends PureComponent {
  render() {
    const { analysis, ...props } = this.props,
      groups = getGroups(analysis.outcomes);
    return groups.map(({ groupIndex, group, outcomes }) => (
      <div className="AnalysisTable" key={groupIndex}>
        <TableGroup group={group} outcomes={outcomes} {...props} />
      </div>
    ));
  }
}

// AnalysisTable.propTypes = {
//   file: fileShape.isRequired,
// };

export default AnalysisTable;
