import React, { useContext, useEffect, useState, Fragment } from "react";
import { Table, Icon, Button, Segment, Header } from "semantic-ui-react";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory, useParams, Link } from "react-router-dom";
import { format } from "date-fns";
import { URL_CYBERSIGHT_PATIENT_CASE } from "../../utils/get_env";
import MetadataFilters from "./StudyTable/MetadataFilters";
import userService from "../../services/user.service";
import UploadService from "../../services/upload.service";
import StudyPagination from "./StudyTable/StudyPagination";
import useWorkspace from "../../hooks/useWorkspace";
import { capitalize } from "../../utils/misc";
import { parseVerboseOutcome, parseLabelboxIntegration, parseDataForExport } from "../../utils/response_parsing";

import { useTranslation } from "react-i18next";
import DataExportButton from "./DataExportButton";

import _ from "lodash";
import { flushSync } from "react-dom";

const WorkspaceStudiesPanel = () => {
  const history = useHistory();
  const [authState, authDispatch] = useContext(AuthContext);
  let { workspace_id } = useParams();

  const [studiesData, setStudiesData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
  const [paginationPerPageLimit, setPaginationPerPageLimit] = useState(15);
  const [paginationSorting, setPaginationSorting] = useState({
    column: "created",
    direction: "descending",
  });
  const [loading, setLoading] = useState(false);

  const [paginationMetadataFilter, setPaginationMetadataFilter] = useState(null);
  const { t, i18n } = useTranslation();
  const ws = useWorkspace(workspace_id);

  const sortableColumns = ["created"];

  useEffect(() => {
    setLoading(true);
    userService
      .getWorkspaceStudies(
        workspace_id,
        paginationPerPageLimit,
        paginationCurrentPage,
        paginationSorting.column,
        paginationSorting.direction,
        paginationMetadataFilter
      )
      .then((data) => {
        setStudiesData(data);
        setLoading(false);
      });
  }, [workspace_id, paginationCurrentPage, paginationPerPageLimit, paginationSorting, paginationMetadataFilter, i18n.language]);

  // Use effect only dependent on the change of workspace
  useEffect(() => {
    setPaginationCurrentPage(1);
  }, [workspace_id]);

  useEffect(() => {
    if (studiesData) {
      let headers = [];

      Object.keys(studiesData.sortable_metadata).forEach((metadata) => {
        headers.push({
          icon: "address card outline",
          text: t(studiesData.sortable_metadata[metadata].info.name_verbose),
          sorting_id: studiesData.sortable_metadata[metadata].info.name,
        });
      });

      headers.push(
        ...[
          {
            icon: "calendar outline",
            text: t("Creation Date"),
            sorting_id: "created",
          },
          {
            icon: "info circle",
            text: t("Status"),
            sorting_id: "status",
          },
          // {
          //   icon: "user outline",
          //   text: capitalize(t("user")),
          //   sorting_id: "user",
          // },
          // {
          //   icon: "microchip",
          //   text: capitalize(t("outcome")),
          //   sorting_id: "outcome",
          // },
          // {
          //   icon: "microchip",
          //   text: capitalize(t("gradability")),
          //   sorting_id: "gradability",
          // },
        ]
      );

      let overviewsToShow = {};

      // If overviews exist in the workspace, we use those, otherwise we fall back to the legacy client-side approach
      if (Object.keys(studiesData.overviews).length == 0)
        headers.push({
          icon: "microchip",
          text: capitalize(t("outcome")),
          sorting_id: "outcome",
        });
      else {
        // Filter the studies.overviews object to only include the overviews that have the visible property set to true
        Object.keys(studiesData.overviews).forEach((overview_key) => {
          if (studiesData.overviews[overview_key].visible) {
            overviewsToShow[overview_key] = studiesData.overviews[overview_key];
          }
        });

        Object.keys(overviewsToShow).forEach((overview_key) => {
          headers.push({
            icon: "microchip",
            text: capitalize(t(overviewsToShow[overview_key].display_name)),
            // sorting_id: overview[overview_key].name,
          });
        });
      }

      if ("labelbox-report" in studiesData.reporting_integrations && studiesData.reporting_integrations["labelbox-report"].enabled) {
        headers.push({
          icon: "pencil alternate",
          text: capitalize(t("labelbox")),
          sorting_id: "outcome",
        });
      }

      setTableHeaders(headers);

      generateRows(overviewsToShow);
    }
  }, [workspace_id, studiesData]);

  const getVerboseCell = (study) => {
    const parsedVerboseOutcome = parseVerboseOutcome(study);

    if (parsedVerboseOutcome) {
      return <Table.Cell key={`${study.id}-verbose`}>{t(parsedVerboseOutcome)}</Table.Cell>;
    } else
      return (
        <Table.Cell key={`${study.id}-verbose`} disabled>
          N/A
        </Table.Cell>
      );
  };

  const getLabelboxIntegrationCell = (study, labelbox_enabled) => {
    const labelboxOutcome = parseLabelboxIntegration(study, studiesData);

    if (labelbox_enabled & (labelboxOutcome != null)) return <Table.Cell key={`${study.id}-labelbox`}>{t(labelboxOutcome)}</Table.Cell>;
    else if (labelbox_enabled & !labelboxOutcome)
      return (
        <Table.Cell key={`${study.id}-labelbox`} disabled>
          N/A
        </Table.Cell>
      );
    else return "";
  };

  const getMetadataCell = (metadata, study) => {
    switch (metadata.name) {
      case "consult-case-id":
        return (
          <Table.Cell key={`${study.id}-${metadata.name}`}>
            {study.study_metadata
              .filter((item) => item.name === metadata.name)
              .map((item_) => (
                <Fragment>
                  <span>
                    <a onClick={(e) => e.stopPropagation()} href={`${URL_CYBERSIGHT_PATIENT_CASE}${item_.value}`}>
                      {item_.value}
                    </a>
                  </span>
                  <Icon name="linkify" />
                </Fragment>
              ))}
          </Table.Cell>
        );

      default:
        let value = null;
        {
          study.study_metadata.filter((item) => item.name === metadata.name).map((item_) => (value = item_.value));
        }

        return (
          <Table.Cell key={`${study.id}-${metadata.name}`} selectable disabled={value ? false : true}>
            <Link to={`/ws/${workspace_id}/${study.id}`}>{value ? value : "N/A"}</Link>
          </Table.Cell>
        );
    }
  };

  const generateOverviewCells = (study, overviewsToShow) => {
    let cells = [];

    Object.keys(overviewsToShow).forEach((overview_key) => {
      const overview = study.overviews.filter((overview) => overview.name === overview_key)[0];

      if (overview) {
        cells.push(
          <Table.Cell
            key={`row-overview-${overview_key}`}
            error={overview.sentiment === "error" ? true : false}
            positive={overview.sentiment === "positive" ? true : false}
            negative={overview.sentiment === "negative" ? true : false}
          >
            {capitalize(t(overview.value))}
          </Table.Cell>
        );
      } else {
        cells.push(
          <Table.Cell key={`row-overview-${overview_key}`} disabled>
            N/A
          </Table.Cell>
        );
      }
    });

    return cells;
  };

  const generateRows = (overviews) => {
    let rows = [];

    studiesData.items.forEach((study) => {
      rows.push(
        <Table.Row key={`row-${study.id}`}>
          {Object.keys(studiesData.sortable_metadata).map((metadata) => getMetadataCell(studiesData.sortable_metadata[metadata].info, study))}
          <Table.Cell selectable>
            <Link to={`/ws/${workspace_id}/${study.id}`}>{format(new Date(study.created), "yyyy-MM-dd HH:mm")}</Link>
          </Table.Cell>
          <Table.Cell selectable>
            <Link to={`/ws/${workspace_id}/${study.id}`}>{study.status}</Link>
          </Table.Cell>
          {/* 
          <Table.Cell selectable>
            <Link to={`/ws/${workspace_id}/${study.id}`}>{study.created_by ? study.created_by.email : "Deleted User"}</Link>
          </Table.Cell> */}

          {/* If overviewers exist in the study, we use those, otherwise we fall back to the legacy client-side approach */}
          {Object.keys(overviews).length == 0 ? getVerboseCell(study) : generateOverviewCells(study, overviews)}
          {getLabelboxIntegrationCell(
            study,
            "labelbox-report" in studiesData.reporting_integrations && studiesData.reporting_integrations["labelbox-report"].enabled
          )}
        </Table.Row>
      );
    });
    setTableRows(rows);
  };

  const handleRefresh = (workspace_id) => {
    UploadService.refreshWorkspaceData(workspace_id, authDispatch);
  };

  if (studiesData)
    return (
      <Segment padded raised loading={loading}>
        <Header
          dividing={true}
          content={capitalize(t("workspace"))}
          icon="clipboard outline"
          subheader={t("List of studies carried out in this workspace. Click on a row to open the detailed study page.")}
        />
        <Table selectable celled stackable compact sortable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={tableHeaders.length} style={{ cursor: "default" }}>
                <MetadataFilters
                  studies_paginated={studiesData}
                  setPaginationMetadataFilter={setPaginationMetadataFilter}
                  workspace_id={workspace_id}
                />
                <Button floated="right" primary size="small" as={Link} to={`/ws/${workspace_id}/new`} disabled={authState.currentWorkspace.archived}>
                  {t("New Study")}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan={tableHeaders.length} style={{ cursor: "default" }}>
                <StudyPagination studies_paginated={studiesData} setPaginationCurrentPage={setPaginationCurrentPage} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Header fullWidth>
            <Table.Row>
              {tableHeaders.map((header) => (
                <Table.HeaderCell
                  key={header.text}
                  onClick={() => {
                    if (sortableColumns.includes(header.sorting_id)) {
                      setPaginationSorting({
                        column: header.sorting_id,
                        direction:
                          paginationSorting.column === header.sorting_id
                            ? paginationSorting.direction === "ascending"
                              ? "descending"
                              : "ascending"
                            : "descending",
                      });
                    }
                  }}
                  sorted={paginationSorting.column === header.sorting_id ? paginationSorting.direction : null}
                >
                  <Icon name={header.icon} />
                  {header.text}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>{tableRows.map((row) => row)}</Table.Body>
        </Table>

        <Table selectable celled stackable compact sortable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="4" style={{ cursor: "default" }}>
                <DataExportButton workspace_id={workspace_id} studiesData={studiesData} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      </Segment>
    );
  else {
    return null;
  }
};

export default WorkspaceStudiesPanel;
