import React, { useContext, useEffect, useState } from "react";
import { Segment, Header, Table, Button, Message, Checkbox } from "semantic-ui-react";
import { AuthContext } from "../contexts/AuthContext";
import WorkspaceAdminPanelAddUsersModal from "./WorkspaceAdminPanelAddUsersModal";
import UserService from "../services/user.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import GeneralAdminPanelCreateUser from "./GeneralAdminPanelCreateUser";

const GeneralAdminPanel = () => {
  const { t, i18n } = useTranslation();
  let { workspace_id } = useParams();

  const [authState, authDispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);

  useEffect(() => {
    setUserMessage(null);
  }, []);

  return (
    <Segment padded raised loading={loading}>
      <Header dividing={true} content={t("Administration Panel")} icon="cogs" subheader={t("General Administration")} />
      {userMessage && (
        <Message positive={userMessage.type === "positive"} negative={userMessage.type === "negative"}>
          {userMessage.msg}
        </Message>
      )}
      <Segment>
        <Checkbox
          toggle
          defaultChecked={authState.hidePersonalWorkspaces}
          label={"Hide Personal Workspaces"}
          onChange={(_, event) => authDispatch({ type: "setHidePersonalWorkspaces", payload: event.checked })}
        />
      </Segment>
      <GeneralAdminPanelCreateUser onCloseFn={() => {}} />
    </Segment>
  );
};

export default GeneralAdminPanel;
