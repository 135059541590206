import React from "react";
import { Icon, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const VerboseOutcomes = ({ outcomes, status }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const parse_urgency = (urgency) => {
    let negative = false;
    let warning = false;
    let positive = false;

    switch (urgency) {
      case "urgent":
        negative = true;
        break;

      case "warning":
        warning = true;
        break;

      case "normal":
        positive = true;
        break;

      default:
        break;
    }

    return {
      negative: negative,
      warning: warning,
      positive: positive,
    };
  };

  if (status == "error") {
    return (
      <Message icon>
        <Icon name="warning" />
        <Message.Content>Issue(s) encountered during processing. Please check expert view for details.</Message.Content>
      </Message>
    );
  }
  else if (status !== "done") {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>Waiting for processing to complete</Message.Content>
      </Message>
    );
  } else if (outcomes.length === 0)
    return (
      <Message icon>
        <Icon name="info circle" />
        <Message.Content>
          This protocol does not provide summaries. Results are available in
          report or in Expert View
        </Message.Content>
      </Message>
    );
  else {
    let messages = outcomes.map((outcome, index) => {
      let urgency_info = parse_urgency(outcome.urgency);

      console.log(currentLanguage)
      return (
        <Message
          negative={urgency_info.negative}
          positive={urgency_info.positive}
          warning={urgency_info.warning}
          size="small"
          floating
          header="Referable Diabetic Retinopathy detected"
        >
          <Message.Header>
            {t(outcome.text["en-US"])}
            {/* {currentLanguage in outcome.text
              ? outcome.text[currentLanguage]
              : t(outcome.text["en-US"])} */}
          </Message.Header>
          <Message.Content>
            {t(outcome.description["en-US"])}
            {/* {currentLanguage in outcome.description
              ? outcome.description[currentLanguage]
              : t(outcome.description["en-US"])} */}
          </Message.Content>
        </Message>
      );
    });

    return messages;
  }
};

export default VerboseOutcomes;
