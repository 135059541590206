import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Image,
  Card,
  Divider,
  Container,
  Segment,
} from "semantic-ui-react";
import DetailedAnalysisView from "./DetailedAnalysisView";
import "react-responsive-modal/styles.css";
import "./Table/index.scss";
import UploadService from "../../services/upload.service";
import {
  CONSULT_WORKSPACE_ID,
  CONSULT_INTEGRATION_TOKEN,
} from "../../utils/get_env";
import { useParams } from "react-router-dom";
import {
  CarouselProvider,
  Slide,
  Slider,
  Dot,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import styled from "styled-components";
import "./index.scss";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const DetailedViewPage = () => {
  const workspace_id = CONSULT_WORKSPACE_ID;

  let { study_id } = useParams();

  const initialStudyState = { analyses: [], done: false, reportURL: null };
  const [loading, setLoading] = useState(true);
  const [selectedAnalysis, setSelectedAnalysis] = useState(0);

  const studyReducer = (state, action) => {
    switch (action.type) {
      case "setStudyObject":
        return {
          ...state,
          analyses: action.payload.analyses,
          created_by: action.payload.created_by,
          created: action.payload.created,
          id: action.payload.id,
          workspace: action.payload.workspace,
          summary_verbose_outcomes: action.payload.verbose_outcomes,
          study_metadata: action.payload.study_metadata,
        };

      case "setAnalysisImage":
        return {
          ...state,
          analyses: state.analyses.map((item) =>
            item.id === action.id ? { ...item, tmp_src: action.payload } : item
          ),
        };

      case "updateAnalysisStatus":
        if (
          state.analyses.filter((item) => item.id === action.id)[0].status ===
          action.status
        ) {
          return state;
        } else
          return {
            ...state,
            analyses: state.analyses.map((item) =>
              item.id === action.id ? { ...item, status: action.status } : item
            ),
          };

      case "setAnalysis":
        return {
          ...state,
          analyses: state.analyses.map((item) =>
            item.id === action.id ? action.analysis : item
          ),
        };

      case "setDone":
        return {
          ...state,
          done: action.payload,
        };

      default:
        console.log(
          `Warning, default case reached in StudyReducer (action=${action.type})`
        );
        return state;
    }
  };

  const [studyState, dispatchStudy] = React.useReducer(
    studyReducer,
    initialStudyState
  );

  useEffect(() => {
    setLoading(true);
    UploadService.getStudyConsult(study_id, CONSULT_INTEGRATION_TOKEN).then(
      (studyObject) => {
        dispatchStudy({ type: "setStudyObject", payload: studyObject });
        setLoading(false);

        if (UploadService.checkAllAnalysesCompleted(studyObject)) {
          dispatchStudy({
            type: "setDone",
            payload: true,
          });
        } else console.log("ERRORR");
        // UploadService.pollStudy(
        //   workspace_id,
        //   study_id,
        //   dispatchStudy,
        //   CONSULT_INTEGRATION_TOKEN
        // );
      }
    );
  }, []);

  useEffect(() => {
    if (UploadService.checkAllAnalysesCompleted(studyState)) {
      if (!studyState.done) {
        dispatchStudy({
          type: "setDone",
          payload: true,
        });
      }
    }
  }, [studyState]);

  const HoverImage = styled(Image)`
    &:hover {
      opacity: 0.7 !important;
    }
  `;

  console.log(studyState);

  const CustomDotGroup = ({ slides, size }) => (
    <Container textAlign="center">
      <Button.Group size={size}>
        {[...Array(slides).keys()].map((slide) => (
          <Button as={Dot} key={slide} icon="circle" slide={slide} />
        ))}
      </Button.Group>
    </Container>
  );

  if (studyState.analyses.length)
    return (
      <Fragment>
        <Segment basic size="small">
          <CarouselProvider
            naturalSlideWidth={50}
            naturalSlideHeight={50}
            totalSlides={studyState.analyses.length}
            visibleSlides={4}
            isIntrinsicHeight={true}
          >
            <Slider style={{ backgroundColor: "black" }}>
              {studyState.analyses.map((analysis, index) => (
                <Slide index={index}>
                  <HoverImage
                    src={analysis.input_src.thumbnail}
                    onClick={() => setSelectedAnalysis(index)}
                    style={{
                      maxHeight: "400px",
                      // objectFit: "contain",
                    }}
                  />
                </Slide>
              ))}
            </Slider>
            <ButtonBack className="buttonBack">Back</ButtonBack>
            <ButtonNext className="buttonNext">Next</ButtonNext>
            <Divider />
          </CarouselProvider>
        </Segment>
        <DetailedAnalysisView
          rawAnalysis={studyState.analyses[selectedAnalysis]}
          static_token={CONSULT_INTEGRATION_TOKEN}
        />
      </Fragment>
    );
  else return null;
};

export default DetailedViewPage;
