import React, { useEffect, useState, Fragment } from "react";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const MetadataFilters = ({
  studies_paginated,
  setPaginationMetadataFilter,
  workspace_id,
}) => {
  const [pageDropdownValues, setPageDropdownValues] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let all_values = [];

    if (studies_paginated.sortable_metadata) {
      Object.keys(studies_paginated.sortable_metadata).forEach((metadata) => {
        let metadata_values = [];

        studies_paginated.sortable_metadata[metadata].data.forEach(
          (metadata_value) => {
            metadata_values.push({
              key: metadata_value,
              text: metadata_value,
              value: { content: metadata_value, metadata_name: metadata },
            });

            all_values.push(metadata_values);
          }
        );
      });
    }

    if (all_values.length == 0) setPageDropdownValues(null);
    else setPageDropdownValues(all_values);
  }, [studies_paginated]);

  useEffect(() => {
    setPaginationMetadataFilter(null);
  }, [workspace_id]);

  if (pageDropdownValues && pageDropdownValues.length > 0) {
    return (
      <Dropdown
        clearable
        search
        options={pageDropdownValues[0]}
        selection
        placeholder={
          t(studies_paginated.sortable_metadata[
            Object.keys(studies_paginated.sortable_metadata)[0]
          ].info.name_verbose)
        }
        onChange={(a, b) => {
          setPaginationMetadataFilter(b.value);
        }}
      />
    );
  } else {
    return null;
  }
};

export default MetadataFilters;
