import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_US from './locales/en-US/translation';
import fr_FR from './locales/fr-FR/translation';
import vi_VN from './locales/vi-VN/translation';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      "en-US": {
        translation: en_US
      },
      "fr-FR": {
        translation: fr_FR
      },
      "vi-VN": {
        translation: vi_VN
      }
    },
    lng: "en-US",
    debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
