import React, { useContext, useEffect, useState } from "react";
import { Segment, Header, Table, Button, Message, Checkbox, Icon, SegmentGroup } from "semantic-ui-react";
import { AuthContext } from "../contexts/AuthContext";
import WorkspaceAdminPanelAddUsersModal from "./WorkspaceAdminPanelAddUsersModal";
import UserService from "../services/user.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WorkspaceAdminPanel = () => {
  const { t, i18n } = useTranslation();
  let { workspace_id } = useParams();

  const [authState, authDispatch] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);

  const handleUserRemoval = (workspace_id, user_id) => {
    setLoading(true);
    UserService.deleteUserFromWorkspace(workspace_id, user_id).then((data) => {
      handleRefresh(workspace_id);
    });
    // setUserMessage({
    //   type: "positive",
    //   msg: `User ${user_id} successfully removed from Workspace`,
    // });
    toast.success(`Successfully removed user from Workspace`, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleWorkspaceArchive = (workspace_id, new_value) => {
    console.log("archiveeeeee");
    setLoading(true);
    UserService.updateWorkspaceArchived(workspace_id, new_value).then((data) => {
      handleRefresh(workspace_id);
    });
  };

  const handleRefresh = (workspace_id) => {
    UserService.refreshWorkspaceData(workspace_id, authDispatch);
  };

  const handlePropertyUpdate = (event, workspace_id, user_id, permission) => {
    UserService.updateUserWorskpacePermissions(workspace_id, user_id, permission, event.checked);
  };

  const handleReportingUpdate = (event, workspace_id) => {
    UserService.updateWorkspaceReporting(workspace_id, event.checked);
  };

  useEffect(() => {
    setLoading(authState.workspaceUpdating);
  }, [authState]);

  useEffect(() => {
    setUserMessage(null);
  }, [workspace_id]);

  return (
    <Segment padded raised loading={loading}>
      <Header dividing={true} content={t("Administration Panel")} icon="cogs" subheader={t("Administration of the Workspace")} />
      {userMessage && (
        <Message positive={userMessage.type === "positive"} negative={userMessage.type === "negative"}>
          {userMessage.msg}
        </Message>
      )}
      <SegmentGroup horizontal>
        <Segment>
          <Checkbox
            toggle
            defaultChecked={authState.currentWorkspace.reporting}
            label={"Include in Reporting"}
            onChange={(_, event) => handleReportingUpdate(event, authState.currentWorkspace.id)}
          />
        </Segment>
        <Segment>
          <Button onClick={(_, event) => handleWorkspaceArchive(authState.currentWorkspace.id, !authState.currentWorkspace.archived)}>
            <Icon name={authState.currentWorkspace.archived ? "unlock" : "lock"} />
            {authState.currentWorkspace.archived ? "Un-archive" : "Archive"} Workspace
          </Button>
        </Segment>
      </SegmentGroup>

      <Table compact celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Data Upload</Table.HeaderCell>
            <Table.HeaderCell>Expert View</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {authState.currentWorkspace.users.map((user) => (
            <Table.Row key={user.id}>
              <Table.Cell collapsing>
                <Button
                  icon="user times"
                  onClick={() => {
                    handleUserRemoval(authState.currentWorkspace.id, user.id);
                  }}
                />
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.id}</Table.Cell>
              <Table.Cell>
                <Checkbox
                  defaultChecked={user.permissions.data_upload}
                  onChange={(_, event) => handlePropertyUpdate(event, authState.currentWorkspace.id, user.id, "data_upload")}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  defaultChecked={user.permissions.results_expert_view}
                  onChange={(_, event) => handlePropertyUpdate(event, authState.currentWorkspace.id, user.id, "results_expert_view")}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="4">
              <WorkspaceAdminPanelAddUsersModal
                onCloseFn={() => {
                  handleRefresh(authState.currentWorkspace.id);
                }}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default WorkspaceAdminPanel;
