import React, { useState, useEffect } from "react";
import { Header, Icon, Grid, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UploadService from "../../services/upload.service";
import _ from "lodash";
import { capitalize } from "../../utils/misc";

const LabelboxReport = ({
  studyState,
  dispatchStudy,
  workspace_id,
  study_id,
}) => {
  const { t, i18n } = useTranslation();
  const [reportLoading, setReportLoading] = useState(false);
  const [labelboxReportData, setLabelboxReportData] = useState(false);

  const reportStatusIndicator = (status, workspace_id, study_id) => {
    switch (status) {
      case "available":
        return (
          <Link
            onClick={() => {
              setReportLoading(true);
              let newTab = window.open(
                window.location.origin + "/report-temporary/",
                "_blank"
              );
              UploadService.getHumanReport(workspace_id, study_id, null)
                .then((data) => {
                  setReportLoading(false);

                  newTab.location.href = data.pdf;
                })
                .catch(
                  (error) =>
                  // console.log("COULD NOT DOWNLOAD PDF")
                  (newTab.location.href =
                    window.location.origin + "/report-temporary/?error=true")
                );
            }}
          >
            <Icon name="check circle" color="green" />
            {capitalize(t("available"))}
          </Link>
        );

      case "error":
        return (
          <span>
            <Icon name="warning sign" color="red" />
            Error fetching data from Labelbox
          </span>
        );

      case "incomplete":
        return (
          <span>
            <Icon name="warning circle" color="orange" />
            {capitalize(t("grading incomplete"))}

          </span>
        );

      case "not-started":
        return (
          <span>
            <Icon name="warning circle" color="orange" />
            {capitalize(t("grading incomplete"))}
          </span>
        );

      case "unknown":
        return (
          <span>
            <Loader active inline size="small" />
            {"  "} Loading status
          </span>
        );

      default:
        return (
          <span>
            <Icon name="warning sign" color="red" />
            Error fetching data from Labelbox
          </span>
        );
    }
  };

  useEffect(() => {
    if (
      studyState &&
      studyState.protocol_details &&
      studyState.protocol_details.reporting_integrations &&
      "labelbox-report" in studyState.protocol_details.reporting_integrations &&
      studyState.protocol_details.reporting_integrations["labelbox-report"]
        .enabled
    ) {
      const labelboxData = _.find(
        studyState.integration_data,
        (datum) => datum.name === "labelbox-report"
      );

      if (!labelboxData) {
        // Backcompatibility with previous approach
        UploadService.getIntegratonStatus(
          studyState.workspace.id,
          studyState.id,
          "labelbox-report"
        ).then((data) => {
          setLabelboxReportData({
            status: data.status,
            data: null,
          });
        });
      } else setLabelboxReportData(labelboxData);
    }
  }, [studyState]);

  if (labelboxReportData) {
    console.log(labelboxReportData);
    return (
      <Grid.Row>
        <Grid.Column width={4}>
          <Header
            as="h5"
            content={t("Labelbox Report")}
            style={{ marginBottom: "0px" }}
          />
          {reportStatusIndicator(
            labelboxReportData.status,
            workspace_id,
            study_id
          )}
        </Grid.Column>
      </Grid.Row>
    );
  } else return null;
};

export default LabelboxReport;
