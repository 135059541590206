import React, { Fragment, useState } from "react";
import { Button, Icon, Segment } from "semantic-ui-react";
import DetailedAnalysisView from "./DetailedAnalysisView";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./Table/index.scss";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";
const styles = {
  modal: {
    backgroundColor: "transparent",
    boxShadow: "none",
    display: "flex",
    overflow: "none",
    width: "100%",
    padding: "25px",
    margin: "0",
    height: "100%",
    minWidth: "100%",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "#1cccc",
    padding: 0,
  },
  closeIcon: {
    fill: "#00000",
  },
  closeButton: {
    top: "25px",
    right: "25px",
  },
};

const DetailedViewModal = ({ analysis, status }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        styles={styles}
        animationDuration={400}
        focusTrapped={true}
        showCloseIcon={true}
      >
        <DetailedAnalysisView rawAnalysis={analysis} />
      </Modal>
      <Button
        onClick={() => setModalVisible(true)}
        floated={"right"}
        style={{ float: "right" }}
        size="mini"
        compact
      >
        <Icon name="doctor" />
        {capitalize(t("expert view"))}
      </Button>
    </Fragment>
  );
};

export default DetailedViewModal;
