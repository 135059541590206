import userService from "../services/user.service";

export const updateWorkspace = (workspace_id, authState, authDispatch) => {
  authDispatch({
    type: "setWorkspaceUpdating",
    payload: true,
  });

  userService.getWorkspace(workspace_id).then((data) => {
    authDispatch({
      type: "setCurrentWorkspace",
      payload: data,
    });
  });
};
