import React, { Fragment, useState, useContext } from "react";
import { Segment, Header, Button, Icon, Grid, Label, Image, Radio, Placeholder } from "semantic-ui-react";
import StatusLabel from "./Study/StatusLabel";
import VerboseOutcomes from "./VerboseOutcomes";
import AnalysisGradability from "./DetailedView/Gradability";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/misc";
import DetailedViewModal from "./DetailedView/DetailedViewModal";
import { AuthContext } from "../contexts/AuthContext";

const AnalysisItem = ({ analysis, analysis: { tmp_src, input_src, filename, status, verbose_outcomes } }) => {
  let img_src;
  if (tmp_src) img_src = tmp_src;
  else if (input_src) img_src = input_src.thumbnail;
  const { t, i18n } = useTranslation();
  const [authState, authDispatch] = useContext(AuthContext);

  return (
    <Fragment>
      <Segment key={filename} padded={false} color="blue">
        <Label attached="top">
          <Icon name="file" />
          {filename}
        </Label>

        <Grid stackable className="StudyGrid">
          <Grid.Row>
            <Grid.Column width={3} verticalAlign={"middle"} textAlign={"center"}>
              {img_src ? (
                <Image
                  src={img_src}
                  style={{
                    width: "100%px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              ) : (
                <Placeholder style={{ height: 100, width: 120 }}>
                  <Placeholder.Image square />
                </Placeholder>
              )}
              <AnalysisGradability
                outcomes={analysis.ai_outcomes}
                shortened={false}
                exclude={["fundus_macula_gradability", "fundus_disc_gradability", "fundus_retina_gradability"]}
                circular={true}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              <Grid.Row>
                <StatusLabel status={status} />
                {authState.currentWorkspace.user_permissions.results_expert_view && <DetailedViewModal analysis={analysis} />}
              </Grid.Row>
              <Segment raised>
                <Header as="h5">{t("Main Findings")}</Header>
                <VerboseOutcomes outcomes={verbose_outcomes} status={status} />
              </Segment>
              <Grid.Row>
                {analysis.tags && (
                  <Label.Group tag size="small">
                    {analysis.tags.map((tag) => (
                      <Label key={tag}>{t(capitalize(tag))}</Label>
                    ))}
                  </Label.Group>
                )}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default AnalysisItem;
