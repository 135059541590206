import React, { Fragment } from "react";
import { Segment, Header, Icon, Button } from "semantic-ui-react";

import InputImageItem from "./InputImageItem";
import classnames from "classnames";
import InteractiveScreenshot from "./InteractiveScreenshot";
import { useTranslation } from "react-i18next";

const InputImagesPanel = ({
  studyState,
  dispatchStudy,
  possibleTags,
  isDragActive,
  openFileModalFn,
  getRootProps,
  getInputProps,
}) => {
  // Used to dyamically change the class to have the on-drop glow effect
  const className = classnames("Upload", { active: isDragActive });
  const { t, i18n } = useTranslation();

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Fragment>
        <Header
          dividing={true}
          content={t("Input Images")}
          as={"h2"}
          // icon="upload"
          subheader={t("Use the add files button or drag-and-drop here")}
        />
        <Button
          icon="file image"
          primary
          onClick={openFileModalFn}
          content={t("Add from file")}
        />

        {/* hide add from window functionality button */}
        {/* <InteractiveScreenshot dispatchStudy={dispatchStudy} /> */}

        <Segment.Group stacked>
          {!studyState.uploadInputs.length && (
            <Segment placeholder className={className}>
              <Header icon size="small">
                <Icon name="upload" /> {t("No images added yet")}
              </Header>
            </Segment>
          )}
          {studyState.uploadInputs.map((analysis) => (
            <InputImageItem
              studyState={studyState}
              dispatchStudy={dispatchStudy}
              possibleTags={possibleTags}
              analysis={analysis}
            />
          ))}
        </Segment.Group>
      </Fragment>
    </div>
  );
};

export default InputImagesPanel;
