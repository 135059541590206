import React, { Fragment, useContext, useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Container } from "semantic-ui-react";
import Admin from "./Admin";
import Navbar from "./Navbar";
import NewStudy from "./NewStudy/NewStudy";
import UserStudies from "./UserStudies";
import Study from "./Study";
import Workspace from "./Workspace";
import ReportTemporary from "./ReportTemporary";
import SAMLRedirect from "./SAMLRedirect";

import InitialWorkspaceSelectionMenu from "./InitialWorkspaceSelectionMenu";
import { AuthContext } from "../contexts/AuthContext";
import { Loader, Message, Icon, Segment } from "semantic-ui-react";
import ReactGA from "react-ga";
import DetailedViewPage from "./DetailedView/DetailedViewPage";

const ProtectedRoute = ({ path, component, nonav }) => {
  const [authState, authDispatch] = useContext(AuthContext);

  if (authState.authenticated & nonav) {
    return <Route path={path} component={component} />;
  } else if (authState.authenticated) {
    return (
      <Fragment>
        <Navbar />
        <Route path={path} component={component} />
      </Fragment>
    );
  } else {
    // return <Redirect to="/login" />;
    if (authState.userUpdating) {
      console.log("logging you in");
      return (
        <Fragment>
          <Segment basic />
          <Segment basic>
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Logging you in</Message.Header>
                Please wait while the system logs you in
              </Message.Content>
            </Message>
          </Segment>
        </Fragment>
      );
    } else {
      return <Route path={path} component={SAMLRedirect} />;
    }
  }
};

const Routes = () => {
  // const [authState, authDispatch] = useContext(AuthContext);

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  // useEffect(() => {
  //   const tokenParam = new URLSearchParams(location.search).get("token");

  //   if (tokenParam) {
  //     AuthService.loginFromExistingToken(tokenParam);
  //     authDispatch({ type: "login" });
  //     history.push("/me");
  //   }
  // }, [location]);

  return (
    <Fragment>
      <Container text>
        <Route exact path="/report-temporary/" component={ReportTemporary} />
      </Container>

      <ProtectedRoute
        path="/consult/:study_id/"
        component={DetailedViewPage}
        nonav={true}
      />

      <Container text>
        <Switch key={"unprotected"}>
          {/* <Route exact path="/" component={UserStudies} /> */}
          {/* <Route exact path="/login" component={Login} /> */}
          <Route exact path="/admin" component={Admin} />
        </Switch>
      </Container>
      <Container className="AppContent">
        <Switch key={"protected"}>
          <ProtectedRoute exact path="/" component={UserStudies} />
          <ProtectedRoute
            exact
            path="/ws/"
            component={InitialWorkspaceSelectionMenu}
          />

          <ProtectedRoute
            exact
            path="/ws/:workspace_id/"
            component={Workspace}
          />
          <ProtectedRoute exact path="/me/" component={UserStudies} />
          <ProtectedRoute
            exact
            path="/ws/:workspace_id/new"
            component={NewStudy}
          />
          <ProtectedRoute
            path="/ws/:workspace_id/:study_id/"
            component={Study}
          />
        </Switch>
      </Container>
    </Fragment>
  );
};

export default withRouter(Routes);
