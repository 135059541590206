import React, { Fragment, useState, useContext, useEffect } from "react";
import { Icon, Menu, Segment, Table, Statistic, Header, Label } from "semantic-ui-react";
import { useHistory, NavLink } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import UserPageStudies from "./UserPage/UserPageStudies";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/misc";
import GeneralAdminPanel from "./GeneralAdminPanel";

const UserStudies = () => {
  const [authState, authDispatch] = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    authDispatch({
      type: "setCurrentWorkspace",
      payload: null,
    });
  }, []);

  if (loading) return null;

  if (authState.userInformation) {
    return (
      <Fragment>
        <Segment raised loading={loading}>
          <Header as="h1" dividing>
            <Icon name="user" />
            <Header.Content>
              {authState.userInformation.email}
              <Header.Subheader>
                {authState.userInformation.super_admin && <Label color="purple" horizontal content={capitalize(t("administrator"))} />}
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Statistic.Group widths="two" size={"tiny"}>
            <Statistic>
              <Statistic.Value>{authState.userInformation.workspaces.length}</Statistic.Value>
              <Statistic.Label>
                <Icon name="desktop" />
                {t("workspace", {
                  count: authState.userInformation.workspaces.length,
                })}
              </Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{authState.userInformation.studies_count}</Statistic.Value>
              <Statistic.Label>
                <Icon name="clipboard outline" />
                {capitalize(t("study", { count: authState.userInformation.studies_count }))}
              </Statistic.Label>
            </Statistic>
          </Statistic.Group>
          {authState.userInformation.super_admin && <GeneralAdminPanel />}
          <Segment padded raised loading={loading}>
            <Header dividing={true} content={t("workspace_other")} icon="desktop" subheader={t("List of workspaces the user is associated with.")} />
            <Menu className="OrganizationMenu" vertical>
              {authState.userInformation.workspaces.map(
                (ws) =>
                  !(authState.hidePersonalWorkspaces && ws.name.includes("Personal")) && (
                    <Menu.Item as={NavLink} key={ws.id} exact={true} to={`/ws/${ws.id}/`} className="SiteMenuItem" icon="chevron right">
                      {ws.name} {ws.archived && <Icon name="lock" />}{" "}
                      {authState.userInformation.super_admin && ws.reporting && <Label>Reporting</Label>}
                    </Menu.Item>
                  )
              )}
            </Menu>
          </Segment>
          <UserPageStudies />
        </Segment>
      </Fragment>
    );
  } else return "";
};

export default UserStudies;
