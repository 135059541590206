import React from "react";
import { Pagination } from "semantic-ui-react";

const StudyPagination = ({ studies_paginated, setPaginationCurrentPage }) => {
  const handleChange = (event, component) => {
    setPaginationCurrentPage(component.activePage);
  };

  if (studies_paginated) {
    return (
      <Pagination
        totalPages={studies_paginated.pages}
        onPageChange={handleChange}
        activePage={studies_paginated.page}
        size={"small"}
      />
    );
  } else {
    return null;
  }
};

export default StudyPagination;
