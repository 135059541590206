import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { sl } from "date-fns/locale";

const UploadTagForm = ({ possibleTags, filename, dispatchStudy, studyState, laterality, fov, disabled }) => {
  const [formState, setFormState] = useState({});

  const { t, i18n } = useTranslation();

  const handleChange = (e, data, target) => {
    const value = data.value;

    setFormState((prevState) => ({
      ...prevState,
      [target]: value,
    }));
    e.stopPropagation();
  };

  useEffect(() => {
    let initialState = {};

    possibleTags.forEach((tag) => {
      if (tag.name == "eye" && laterality != null) {
        console.log("@@  " + `${laterality}-eye`);
        initialState[tag.name] = `${laterality}-eye`;
      } else if (tag.name == "fov" && fov != null) {
        initialState[tag.name] = fov.replace("_", "-").replace("centred", "centered");
        console.log("@@  " + fov);
      } else {
        initialState[tag.name] = null;
      }
    });

    setFormState(initialState);
  }, [possibleTags, laterality, fov]);

  useEffect(() => {
    dispatchStudy({
      type: "updateUploadItemTags",
      filename: filename,
      tags: formState,
    });
  }, [filename, formState]);

  const formElements = possibleTags.map((tag) => {
    switch (tag.type) {
      case "radio":
        let newState = {};
        newState[tag.name] = null;

        return (
          <Form.Group key={tag.name} className="EyeFormGroup" inline>
            <label>{t(tag.text)}</label>
            {tag.values.map((value) => (
              <Form.Radio
                label={t(value.text)}
                value={value.value}
                key={`${filename}_${value.value}`}
                checked={formState[tag.name] === value.value}
                onChange={(event, data) => handleChange(event, data, tag.name)}
                onClick={(e) => e.stopPropagation()}
                disabled={disabled}
              />
            ))}
          </Form.Group>
        );

      case "dropdown":
        console.log(tag.values);
        return (
          <Form.Group key={tag.name} className="EyeFormGroup" inline>
            <label>{t(tag.text)}</label>
            <Dropdown
              placeholder={t("Field of View")}
              fluid
              selection
              options={tag.values}
              onChange={(event, data) => handleChange(event, data, tag.name)}
              onClick={(e) => e.stopPropagation()}
              disabled={disabled}
            />
          </Form.Group>
        );

      default:
    }
  });

  return <Form disabled={disabled}>{formElements}</Form>;
};

export default UploadTagForm;
