import React, { useEffect, Fragment } from "react";
import { Message, Icon, Segment } from "semantic-ui-react";
import { API_URL, SITE_URL } from "../utils/get_env";

const SAMLRedirect = ({ history }) => {
  useEffect(() => {
    window.location =
      API_URL + `/saml?sso&refer_url=${SITE_URL}${history.location.pathname}`;
  }, []);

  return (
    <Fragment>
      <Segment basic />
      <Segment basic>
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>
              Redirecting to Cybersight login form
            </Message.Header>
            Please wait while we redirect you
          </Message.Content>
        </Message>
      </Segment>
    </Fragment>
  );
};

export default SAMLRedirect;
