import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Modal,
  Header,
  Icon,
  Button,
  Message,
} from "semantic-ui-react";
import UserService from "../services/user.service";
import { AuthContext } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WorkspaceAdminPanelAddUsersModal = ({ onCloseFn }) => {
  const [userCandidates, setUserCandidates] = useState(null);
  const [userMessage, setUserMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const [authState, authDispatch] = useContext(AuthContext);

  const { t, i18n } = useTranslation();

  const handleOpen = () => {
    setLoading(true);

    UserService.getWorkspaceUserCandidates(authState.currentWorkspace.id)
      .then((data) => {
        const candidates = data.map((user) => {
          return {
            key: user.id,
            value: user.id,
            text: `${user.email} - ${user.id}`,
          };
        });
        setUserCandidates(candidates);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setUserMessage({
          type: "error",
          msg: "Could not retrieve user list",
        });
      });
  };

  const handleClose = () => {
    onCloseFn();
    setUserMessage(null);
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        floated="right"
        icon
        labelPosition="left"
        primary
        size="small"
        onClick={() => {
          setOpen(true);
          handleOpen();
        }}
      >
        <Icon name="user" /> {t("Add User")}
      </Button>
      <Modal
        as={Form}
        open={open}
        // onSubmit={(e) => handleSubmit(e)}
        size="tiny"
      >
        <Header
          icon="user"
          content="Select user to add to the Workspace"
          as="h4"
        />
        <Modal.Content>
          {userMessage && (
            <Message
              positive={userMessage.type === "positive"}
              negative={userMessage.type === "negative"}
            >
              {userMessage.msg}
            </Message>
          )}
          <Form.Group>
            <Dropdown
              //   clearable
              fluid
              //   multiple
              search
              selection
              loading={loading}
              options={userCandidates}
              placeholder="Select user to add"
              onChange={(obj, event) => {
                setSelectedUser(event.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Button
              type="submit"
              primary
              //   color="green"
              content="Add User"
              loading={loading}
              onClick={() => {
                setLoading(true);
                UserService.addUserToWorkspace(
                  authState.currentWorkspace.id,
                  selectedUser
                )
                  .then((result) => {
                    setLoading(false);
                    // setUserMessage({
                    //   type: "positive",
                    //   msg: `Successfully added ${selectedUser} to Workspace`,
                    // });
                    toast.success(`Successfully added user`, {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  })
                  .catch((error) => {
                    setLoading(false);
                    // setUserMessage({
                    //   type: "negative",
                    //   msg: "Could not retrieve user information",
                    // });
                    toast.error(`Failed to add user`, {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
              }}
            />
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="submit"
            color="red"
            icon="times"
            content="Close"
            loading={loading}
            onClick={() => {
              handleClose();
            }}
          />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default WorkspaceAdminPanelAddUsersModal;
