//TODO: Use axios intercept for automated token refresh

import axios from "axios";

import { API_URL } from "../utils/get_env";

const create401Interceptor = (dispatch) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 401) {
          console.log("401 detected");
          logout();
          dispatch({ type: "logout" });
        }
      }
      return error;
    }
  );
  console.log("Created 401 intercceptor");
};

// We store user data (e.g. in case of reload) and also return it for immediate consumption
const login = (email, password) => {
  return axios
    .post(`${API_URL}/login`, {
      email,
      password,
    })
    .then((response) => {
      const authData = {
        access_token: response.data.access_token,
        expires_at: Date.now() + 1000 * 60 * 120, // default to two hours duration
      };
      localStorage.setItem("user", JSON.stringify(authData));
      return authData;
    });
};

const loginFromExistingToken = (token, languageId) => {
  const authData = {
    access_token: token,
    languageId: languageId,
    expires_at: Date.now() + 1000 * 60 * 120, // default to two hours duration
  };

  localStorage.setItem("user", JSON.stringify(authData));
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const tokenExistsAndValid = () => {
  const authData = JSON.parse(localStorage.getItem("user"));
  if (authData && authData.access_token && authData.expires_at > Date.now())
    return true;
  else {
    // logout();
    return false;
  }
};

export default {
  login,
  logout,
  getCurrentUser,
  create401Interceptor,
  tokenExistsAndValid,
  loginFromExistingToken,
};
