import React, { Fragment } from "react";
import { Label, Icon, Progress } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const StatusLabelNewStudy = ({
  status,
  verified,
  gradable,
  uploadProgress,
}) => {
  const { t, i18n } = useTranslation();

  if (verified !== null && status != "error") {
    if (!verified) {
      return (
        <Label color="red">
          Status
          <Label.Detail>{t("Failed Verification")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="close" />
        </Label>
      );
    } else if (verified && !gradable) {
      return (
        <Label color="orange" size="small">
          Status
          <Label.Detail>{t("Image not Gradable")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="warning" />
        </Label>
      );
    } else if (verified && gradable) {
      return (
        <Label color="green" size="small">
          Status
          <Label.Detail>
            {t("Verified and Gradable")} &nbsp; &nbsp;
          </Label.Detail>
          <Icon name="check" />
        </Label>
      );
    }
  }

  switch (status) {
    case "rejected":
      return (
        <Label color="red">
          Status
          <Label.Detail>
            {t("Error, please check file type supported")} &nbsp; &nbsp;
          </Label.Detail>
          <Icon name="close" />
        </Label>
      );

    case "uploading":
      return (
        <Fragment>
          <Progress
            percent={uploadProgress}
            attached="top"
            color="purple"
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
          />
          <Label color="grey">
            Status
            <Label.Detail>
              {t("Uploading and Checking")} &nbsp; &nbsp;
            </Label.Detail>
            <Icon name="spinner" loading />
          </Label>
        </Fragment>
      );

    default:
      return (
        <Label basic color="grey" size="small">
          Status
          <Label.Detail>{status} &nbsp; &nbsp;</Label.Detail>
          {/* <Icon name="check" /> */}
        </Label>
      );
  }
};

export default StatusLabelNewStudy;
