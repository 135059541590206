import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import AuthService from "../services/auth.service";

import { URL_CYBERSIGHT_SLO } from "../utils/get_env";

const Logout = () => {
  const { t, i18n } = useTranslation();
  const handleLogout = () => {
    AuthService.logout();
    // authDispatch({ type: "logout" });
    window.location = URL_CYBERSIGHT_SLO;
    // history.push("/me");
  };

  return (
    <Dropdown.Item icon="power off" onClick={handleLogout} text={t("Logout")} />
  );
};

export default Logout;
