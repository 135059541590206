const setLanguage = (i18n, language) => {
    i18n.changeLanguage(language)
    setPersistedLanguage(language)
}

const setPersistedLanguage = (language) => {
    localStorage.setItem("language", language);
};

const getPersistedLanguage = () => {
    return localStorage.getItem("language");
};

export default { setLanguage, setPersistedLanguage, getPersistedLanguage }