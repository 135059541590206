import React, { Fragment } from "react";
import { Header, Grid, Form } from "semantic-ui-react";

import ProtocolInputList from "./ProtocolInputList";
import MetadataList from "./MetadataList";
import { useTranslation } from "react-i18next";

const ProtocolPanel = ({
  inputSpecs,
  validatedInputSpecs,
  validationErrors,
  dispatchStudy,
  studyState,
  authState,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <Header dividing={true} content={t("Study protocol")} as="h2" />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={12}>
            <div>{authState.currentWorkspace.protocol_details.description}</div>
          </Grid.Column>
        </Grid.Row>
        {studyState.metadata.length > 0 && (
          <Grid.Row>
            <Grid.Column width={12}>
              <Header as="h3">{t("Study Data")}</Header>
              <MetadataList
                studyState={studyState}
                dispatchStudy={dispatchStudy}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={12}>
            <Header as="h3">{t("Expected Inputs")}</Header>
            <ProtocolInputList
              inputSpecs={inputSpecs}
              validatedInputSpecs={validatedInputSpecs}
              validationErrors={validationErrors}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default ProtocolPanel;
