import React, { useState, useContext, Fragment } from "react";
import { Loader, Message, Icon, Segment } from "semantic-ui-react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ReportTemporary = () => {
  let query = useQuery();
  const error = query.get("error");

  console.log(error);

  if (error) {
    return (
      <Fragment>
        <Segment basic />
        <Segment basic>
          <Message icon error>
            <Icon name="bug" />
            <Message.Content>
              <Message.Header>Error Generating Report</Message.Header>
              The error has been reported to the Cybersight AI admin team. If it
              persists, please contact support@cybersight.org.
            </Message.Content>
          </Message>
        </Segment>
      </Fragment>
    );
  } else
    return (
      <Fragment>
        <Segment basic />
        <Segment basic>
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Generating Report</Message.Header>
              The content of the report will be displayed in this window.
            </Message.Content>
          </Message>
        </Segment>
      </Fragment>
    );
};

export default ReportTemporary;
