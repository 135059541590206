import axios from "axios";

import authHeader from "./auth-header";

import { API_URL } from "../utils/get_env";

import { updateWorkspace } from "../utils/updateWorkspace";

const updateUserInformation = () => {
  return axios
    .get(`${API_URL}/users/me/details`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getUserStudies = (limit, page, sort_column, sort_direction, metadata_filter) => {
  let url = `${API_URL}/users/me/studies?limit=${limit}&page=${page}&order_by=${sort_column}&order_direction=${sort_direction}`;

  if (metadata_filter) url += `&metadata_filter_name=${metadata_filter.metadata_name}&metadata_filter_value=${metadata_filter.content}`;

  return axios.get(url, { headers: authHeader() }).then((response) => {
    return response.data;
  });
};

// Gets the latest Workspace and calls the dispatch function to refresh relevant components
const refreshWorkspaceData = (workspace_id, dispatchFn) => {
  updateWorkspace(workspace_id, null, dispatchFn);
};

// Replaced by updateUserInformation
// const getUserDetails = () => {
//   return axios
//     .get(`${API_URL}/users/me/details/`, { headers: authHeader() })
//     .then((response) => {
//       return response.data;
//     });
// };

const getWorkspace = (workspace_id, limit, page) => {
  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const getWorkspaceStudies = (workspace_id, limit, page, sort_column, sort_direction, metadata_filter) => {
  let url = `${API_URL}/workspaces/${workspace_id}/studies/?limit=${limit}&page=${page}&order_by=${sort_column}&order_direction=${sort_direction}`;

  if (metadata_filter) url += `&metadata_filter_name=${metadata_filter.metadata_name}&metadata_filter_value=${metadata_filter.content}`;

  return axios
    .get(url, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const getWorkspaceProtocol = (workspace_id) => {
  return axios.get(`${API_URL}/workspaces/${workspace_id}/protocol`, {
    headers: authHeader(),
  });
};

const getWorkspaceUserCandidates = (workspace_id) => {
  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/users/candidates/`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const addUserToWorkspace = (workspace_id, user_id) => {
  return axios
    .put(`${API_URL}/workspaces/${workspace_id}/users/${user_id}`, [], {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const deleteUserFromWorkspace = (workspace_id, user_id) => {
  return axios
    .delete(`${API_URL}/workspaces/${workspace_id}/users/${user_id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

// CSAI-107, CSAI-108s
const getWorkspaceStudiesforExport = (workspace_id, limit) => {
  let url = `${API_URL}/workspaces/${workspace_id}/studies/?limit=${limit}`;

  return axios
    .get(url, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateUserWorskpacePermissions = (workspace_id, user_id, permission, new_value) => {
  let url = `${API_URL}/workspaces/${workspace_id}/users/${user_id}/permissions/${permission}/${new_value}/`;

  return axios
    .put(
      url,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const createNewSSOUser = (email) => {
  let url = `${API_URL}/users/sso`;

  return axios
    .post(
      url,
      { email: email },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateWorkspaceReporting = (workspace_id, new_value) => {
  let url = `${API_URL}/workspaces/${workspace_id}/reporting/${new_value}/`;

  return axios
    .put(
      url,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateWorkspaceArchived = (workspace_id, new_value) => {
  let url = `${API_URL}/workspaces/${workspace_id}/archived/${new_value}`;
  return axios
    .put(
      url,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  updateUserInformation,
  getWorkspaceProtocol,
  getUserStudies,
  getWorkspaceStudies,
  getWorkspace,
  getWorkspaceUserCandidates,
  addUserToWorkspace,
  deleteUserFromWorkspace,
  refreshWorkspaceData,
  getWorkspaceStudiesforExport,
  updateUserWorskpacePermissions,
  createNewSSOUser,
  updateWorkspaceReporting,
  updateWorkspaceArchived,
};
