import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Popup } from "semantic-ui-react";

// import { outcomeShape } from "../../../types/outcome";
import { getSliceResult } from "../../../utils/analysis_helper";

const GRADABILITY_WARNING =
  "This slice is considered to be of poor quality. Please note that analysis results for this slice may be unreliable.";

class VisualisationIndicator extends PureComponent {
  render() {
    const { outcomes, selectedSlice, totalSlices } = this.props,
      [gradabilityValue, gradabilityThreshold] = getSliceResult(
        outcomes,
        "oct_quality_score",
        "oct_quality_score",
        selectedSlice
      ),
      warning = gradabilityValue && gradabilityValue > gradabilityThreshold,
      label = (
        <Label
          className="VisualisationIndicator"
          icon={warning ? "warning sign" : null}
          color={warning ? "yellow" : null}
          content={
            <Fragment>
              {selectedSlice + 1}
              <small> / {totalSlices}</small>
            </Fragment>
          }
        />
      );
    return warning ? (
      <Popup trigger={label} content={GRADABILITY_WARNING} />
    ) : (
      label
    );
  }
}

// VisualisationIndicator.propTypes = {
//   outcomes: PropTypes.arrayOf(outcomeShape).isRequired,
//   selectedSlice: PropTypes.number.isRequired
// };

export default VisualisationIndicator;
