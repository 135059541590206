import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import MetadataInput from "./MetadataInput";

const MetadataList = ({ dispatchStudy, studyState }) => {
  return (
    <Fragment>
      <Form>
        {studyState.metadata.map((metadata) => (
          <MetadataInput
            key={`metadata-input-${metadata.name}`}
            studyState={studyState}
            metadata={metadata}
            dispatchStudy={dispatchStudy}
          />
        ))}
      </Form>
    </Fragment>
  );
};

export default MetadataList;
