import React, { useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ProtocolInputList = ({
  inputSpecs,
  validationErrors,
  validatedInputSpecs,
}) => {
  const [metaValidationErrors, setMetaValidationErrors] = useState([]);

  useEffect(() => {
    setMetaValidationErrors(
      validationErrors.filter(
        (error) =>
          error.type === "TOO_MANY_INPUTS" || error.type === "TOO_FEW_INPUTS"
      )
    );
  }, [validationErrors]);

  const { t, i18n } = useTranslation();

  console.log(validatedInputSpecs);
  if (!validatedInputSpecs.inputs) return null;
  else
    return (
      <List divided relaxed>
        <List.Item>
          {!metaValidationErrors.length ? (
            <List.Icon color="green" name="check circle outline" />
          ) : (
            <List.Icon name="circle outline" />
          )}
          <List.Content>
            {validatedInputSpecs.minInputs ===
              validatedInputSpecs.maxInputs && (
                <List.Header>
                  {validatedInputSpecs.maxInputs} images in total are required
                </List.Header>
              )}

            {validatedInputSpecs.maxInputs > validatedInputSpecs.minInputs && (
              <List.Header>
                {
                  t("At least {{minImages}} images required (maximum of {{maxImages}})", { minImages: validatedInputSpecs.minInputs, maxImages: validatedInputSpecs.maxInputs })
                }
              </List.Header>
            )}
            {validationErrors
              .filter(
                (error) =>
                  error.type === "TOO_MANY_INPUTS" ||
                  error.type === "TOO_FEW_INPUTS"
              )
              .map((error) => (
                <List.Description>{error.text}</List.Description>
              ))}
          </List.Content>
        </List.Item>
        {inputSpecs.inputs.map((tagObject) =>
          tagObject.parsed_tags.length ? (
            <List.Item key={`tag-${tagObject.name}`}>
              {validatedInputSpecs.inputs.includes(tagObject.name) &&
                !validationErrors.filter(
                  (error) => error.input === tagObject.name
                ).length ? (
                <List.Icon color="green" name="check circle outline" />
              ) : (
                <List.Icon name="circle outline" />
              )}

              <List.Content>
                <List.Header>
                  {tagObject.max_repeat > 1 ? (
                    <span>At least </span>
                  ) : (
                    <span>1 </span>
                  )}
                  {t("Fundus Photograph")} (
                  {tagObject.parsed_tags.map((parsedTag, index) =>
                    index > 0
                      ? `, ${t(parsedTag.value_text)}`
                      : `${t(parsedTag.value_text)}`
                  )}
                  )
                </List.Header>
                {validationErrors
                  .filter((error) => error.input === tagObject.name)
                  .map((error) => (
                    <List.Description key={`${tagObject.name}-${error.type}`}>
                      {error.text}
                    </List.Description>
                  ))}
              </List.Content>
            </List.Item>
          ) : null
        )}
      </List>
    );
};

export default ProtocolInputList;
