import React, { useContext, useState } from "react";
import { Input, Segment } from "semantic-ui-react";
import UserService from "../services/user.service";
import { AuthContext } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const GeneralAdminPanelCreateUser = ({ onCloseFn }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailToAdd, setEmailToAdd] = useState(null);
  const [inputRef, setInputRef] = useState(null);

  const [authState, authDispatch] = useContext(AuthContext);

  const { t, i18n } = useTranslation();

  return (
    <Segment raised loading={loading}>
      <Input
        placeholder="Enter email here"
        fluid
        loading
        onChange={(a, b) => {
          setEmailToAdd(b.value);
          a.persist();
          setInputRef(a);
        }}
        action={{
          labelPosition: "right",
          icon: "add",
          content: "Create User",
          onClick: (a, b) => {
            setLoading(true);
            UserService.createNewSSOUser(emailToAdd)
              .then((result) => {
                setLoading(false);
                inputRef.target.value = "";
                toast.success(`Successfully added user`, {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((error) => {
                setLoading(false);
                toast.error(`Failed to add user`, {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          },
        }}
      />
    </Segment>
  );
};

export default GeneralAdminPanelCreateUser;
