import { hiddenGroups } from "../config.json";

export const toStringItem = (item) =>
    typeof item === "object"
      ? JSON.stringify(item, Object.keys(item).sort())
      : item,
  isFirstOccurence = (item, index, items) => items.indexOf(item) === index,
  isLastOccurence = (item, index, items) => items.lastIndexOf(item) === index,
  removeFirstDuplicates = (items) =>
    items.map(toStringItem).filter(isLastOccurence).map(JSON.parse),
  removeLastDuplicates = (items) =>
    items.map(toStringItem).filter(isFirstOccurence).map(JSON.parse),
  filterSearch = (items, search, propKeys) => {
    if (!search || !propKeys) {
      return items;
    }
    const regexSearch = new RegExp(`(${search})`, "i"),
      matchSearch = (value) => value && regexSearch.test(value),
      getPropValues = (item) => propKeys.map((key) => item[key]),
      bySearchMatch = (item) => getPropValues(item).some(matchSearch);
    return items.filter(bySearchMatch);
  },
  join = (parts, separator = " ") => parts.filter(Boolean).join(separator),
  encodeURIPair = (pair) => pair.map(encodeURIComponent).join("="),
  encodeParams = (params) =>
    Object.entries(params)
      .filter(([key, value]) => value)
      .map(encodeURIPair)
      .join("&"),
  appendQueryParams = (endpoint, params) => {
    if (!params) {
      return endpoint;
    }
    const query = encodeParams(params);
    return query === "" ? endpoint : `${endpoint}?${query}`;
  },
  setFormData = (params) => {
    const data = new FormData();
    Object.entries(params).forEach(([key, value]) => data.append(key, value));
    return data;
  },
  isMergeableObject = (val) =>
    val &&
    typeof val === "object" &&
    Object.prototype.toString.call(val) !== "[object RegExp]" &&
    Object.prototype.toString.call(val) !== "[object Date]",
  emptyTarget = (val) => (Array.isArray(val) ? [] : {}),
  cloneIfNecessary = (value, optionsArgument) =>
    optionsArgument &&
    optionsArgument.clone === true &&
    isMergeableObject(value)
      ? deepmerge(emptyTarget(value), value, optionsArgument)
      : value,
  defaultArrayMerge = (target, source, optionsArgument) => {
    const destination = target.slice();
    source.forEach((e, i) => {
      if (typeof destination[i] === "undefined") {
        destination[i] = cloneIfNecessary(e, optionsArgument);
      } else if (isMergeableObject(e)) {
        destination[i] = deepmerge(target[i], e, optionsArgument);
      } else if (target.indexOf(e) === -1) {
        destination.push(cloneIfNecessary(e, optionsArgument));
      }
    });
    return destination;
  },
  mergeObject = (target, source, optionsArgument) => {
    const destination = {};
    if (isMergeableObject(target)) {
      Object.keys(target).forEach((key) => {
        destination[key] = cloneIfNecessary(target[key], optionsArgument);
      });
    }
    Object.keys(source).forEach((key) => {
      if (!isMergeableObject(source[key]) || !target[key]) {
        destination[key] = cloneIfNecessary(source[key], optionsArgument);
      } else {
        destination[key] = deepmerge(target[key], source[key], optionsArgument);
      }
    });
    return destination;
  },
  deepmerge = (target, source, optionsArgument) => {
    const array = Array.isArray(source),
      options = optionsArgument || { arrayMerge: defaultArrayMerge },
      arrayMerge = options.arrayMerge || defaultArrayMerge;

    if (array) {
      return Array.isArray(target)
        ? arrayMerge(target, source, optionsArgument)
        : cloneIfNecessary(source, optionsArgument);
    } else {
      return mergeObject(target, source, optionsArgument);
    }
  },
  deepmergeAll = (array, optionsArgument) => {
    if (!Array.isArray(array) || array.length < 2) {
      throw new Error(
        "first argument should be an array with at least two elements"
      );
    }
    // we are sure there are at least 2 values, so it is safe to have no initial value
    return array.reduce((prev, next) => deepmerge(prev, next, optionsArgument));
  },
  clean = (target) => {
    for (let prop in target) {
      if (target[prop] === null || target[prop] === undefined) {
        delete target[prop];
      }
    }
    return target;
  },
  byAsc = (prop) => (a, b) => new Date(a[prop]) - new Date(b[prop]),
  byDesc = (prop) => (a, b) => new Date(b[prop]) - new Date(a[prop]),
  argMax = (array) =>
    array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1],
  toggleItems = (list, value) => {
    const result = [...list],
      f = result.indexOf(value);
    if (f === -1) {
      result.push(value);
    } else {
      result.splice(f, 1);
    }
    return result;
  },
  listProximity = (list, value) =>
    list
      .map(Number)
      // .sort((a, b) => a - b)
      .map((entry, index) => {
        // Return proximity relative to direct neighbour entries
        if (entry === value) {
          return 1;
        }
        if (entry < value && list[index + 1] > value) {
          return 1 - (value - entry) / (list[index + 1] - entry);
        }
        if (entry > value && list[index - 1] < value) {
          return 1 - (entry - value) / (entry - list[index - 1]);
        }
        return 0;
      }),
  linspace = (a, b, length) => {
    const list = [],
      n = length - 1;
    if (n) {
      for (let i = 0; i < length; i += 1) {
        list[i] = (i * b + (n - i) * a) / n;
      }
    }
    return list;
  };

export const PRECISION = 2,
  formatValue = (value) => {
    const numberValue = Number(value);
    return numberValue % 1 === 0
      ? numberValue
      : parseFloat(numberValue.toFixed(PRECISION));
  },
  byDateDesc = byDesc("date"),
  byCreatedDesc = byDesc("created"),
  byUploadedDesc = byDesc("uploaded"),
  byOutcomeIndexAsc = byAsc("outcomeIndex"),
  byGroupIndexAsc = byAsc("groupIndex"),
  byBatchDateDesc = (a, b) => {
    // Sort files by uploaded date, or created date if uploaded at the same time
    const uploadedSort = byUploadedDesc(a, b);
    return uploadedSort === 0 ? byCreatedDesc(a, b) : uploadedSort;
  },
  byFileDateDesc = (a, b) => {
    // Sort folders by File batch date, or by date if empty
    const [aFile] = a.files.sort(byBatchDateDesc),
      [bFile] = b.files.sort(byBatchDateDesc);
    return aFile && bFile ? byBatchDateDesc(aFile, bFile) : byDateDesc(a, b);
  },
  getGradientColor = (level) => {
    if (level < 1 / 5) {
      return "green";
    } else if (level < 2 / 5) {
      return "olive";
    } else if (level < 3 / 5) {
      return "yellow";
    } else if (level < 4 / 5) {
      return "orange";
    }
    return "red";
  },
  getScaledTableValues = (threshold = 0, levels = 256) => {
    const t = Math.round(levels * threshold),
      table = linspace(0, 1, levels - t),
      thresholded = Array(t).fill(0);
    return [...thresholded, ...table].join(" ");
  },
  getStepTableValues = (threshold, levels = 256) => {
    const t = Math.round(levels * threshold),
      table = linspace(0, 1, levels);
    return table.map((value, index) => (index < t ? 0 : value)).join(" ");
  },
  getNegativeLabel = (labels) => {
    const negativeIndex = labels.length - 1;
    return labels[negativeIndex];
  },
  getNegativeValue = (values) => {
    const negativeIndex = values.length - 1,
      negativeValue = values[negativeIndex];
    return isNaN(negativeValue) ? null : formatValue(negativeValue);
  },
  getSliceResult = (outcomes, name, id, imageIndex) => {
    const outcome = outcomes.find((outcome) => outcome.name === name),
      result = outcome && outcome.results.find((result) => result.id === id),
      data = result && result.data.find((data) => data.x === imageIndex);
    return [data ? data.y : null, result && result.threshold];
  },
  mapOutcome = ({ aggregateResults, results, colour, ...outcome }) => {
    const outcomeResults =
        aggregateResults && aggregateResults.length
          ? aggregateResults
          : results,
      resultGroups = outcomeResults.reduce(
        (groups, { id, labels, threshold, type, units, values }) => {
          // Multiple results can share the same id
          if (!groups[id]) {
            groups[id] = {
              id,
              labels,
              threshold,
              type,
              units,
              values: values.map(formatValue),
              // Plot
              data: results
                .filter((result) => result.id === id)
                .map((result) => ({
                  x: result.imageIndex,
                  y: getNegativeValue(result.values),
                }))
                .sort((a, b) => a.x - b.x),
              label: getNegativeLabel(labels),
              value: getNegativeValue(values),
              color: colour,
            };
          } else {
            // In case the result wasn't aggregated, keep the most negative values
            const conciliatedValues =
              groups[id].value > getNegativeValue(values)
                ? groups[id].values
                : values.map(formatValue);
            groups[id] = {
              ...groups[id],
              values: conciliatedValues,
              value: getNegativeValue(conciliatedValues),
            };
          }
          return groups;
        },
        {}
      );
    return {
      results: Object.values(resultGroups).map((result, r, groups) => {
        result.title = groups.length === 1 ? outcome.desc : result.label;
        return result;
      }),
      color: colour,
      ...outcome,
    };
  },
  mapAnalysis = ({
    analysis_id,
    ai_outcomes,
    file_object,
    input_src,
    msg,
    status,
  }) => {
    const volume =
        input_src &&
        input_src.aggregateImages &&
        input_src.aggregateImages.find(({ type }) => type === "OCT3DVolume"),
      projection =
        input_src &&
        input_src.aggregateImages &&
        input_src.aggregateImages.find(({ type }) => type === "OCTProjection"),
      analysis = {
        // Need to reset ID for uploads that don't have one yet
        id: analysis_id,
        messages: msg ? [{ content: msg, error: true }] : [],
        outcomes:
          ai_outcomes &&
          ai_outcomes
            .map(mapOutcome)
            .sort(byOutcomeIndexAsc)
            .sort(byGroupIndexAsc),
        status,
        projection: projection && projection,
        maxIndex: input_src && input_src.originals && input_src.originals[0].z,
        thumbnail: input_src && input_src.thumbnail,
        volume,
      };
    return clean(
      file_object
        ? {
            uploaded: file_object.uploaded,
            user_id: file_object.user_id,
            ...analysis,
          }
        : analysis
    );
  },
  getGroups = (outcomes) => {
    console.log(outcomes);
    const groups = outcomes.reduce(
      (outcomeGroups, { group, groupIndex, ...outcome }) => {
        if (hiddenGroups.includes(group)) {
          return outcomeGroups;
        }
        const outcomeGroup = outcomeGroups[groupIndex] || {
          groupIndex,
          group,
          outcomes: [],
        };
        outcomeGroup.outcomes.push({ group, groupIndex, ...outcome });
        return { ...outcomeGroups, [groupIndex]: outcomeGroup };
      },
      {}
    );
    return Object.values(groups);
  };
