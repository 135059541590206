import React, {
  Fragment,
  PureComponent,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { Icon, Image, Loader, Popup } from "semantic-ui-react";
import classNames from "classnames";
import svgPanZoom from "svg-pan-zoom";
import UploadService from "../../../services/upload.service";

import {
  appendQueryParams,
  getStepTableValues,
} from "../../../utils/analysis_helper";
// import useAPI from "../../../hooks/api";

export const ImageSVG = forwardRef(
    ({ src, onSVGLoaded, static_token, ...props }, ref) => {
      // const [data, loading] = useAPI("image", src, (response) =>
      //   response.status === 200 ? response.text() : null
      // );
      const [data, setData] = useState(null);
      const [loading, setLoading] = useState(true);

      useEffect(() => {
        setLoading(true);
        UploadService.getImage(src, static_token).then((apiData) => {
          setData(apiData);
          setLoading(false);
        });
      }, []);

      useEffect(() => {
        if (onSVGLoaded && !loading) {
          onSVGLoaded();
        }
      }, [loading]);

      return loading ? (
        <Loader active={true} />
      ) : (
        <div
          ref={ref}
          className="ImageSVG"
          dangerouslySetInnerHTML={{ __html: data }}
          {...props}
        />
      );
    }
  ),
  SVGTooltip = ({ trigger }) => {
    const [open, setOpen] = useState(false),
      { attributes } = trigger,
      title = attributes.getNamedItem("title"),
      content = title && title.value,
      openTooltip = () => setOpen(true),
      closeTooltip = () => setOpen(false);
    useEffect(() => {
      trigger.addEventListener("mouseenter", openTooltip);
      trigger.addEventListener("mouseleave", closeTooltip);
      return () => {
        trigger.removeEventListener("mouseenter", openTooltip);
        trigger.removeEventListener("mouseleave", closeTooltip);
      };
    }, []);
    return (
      <Popup
        className="SVGTooltip"
        content={content}
        context={trigger}
        inverted={true}
        open={open}
        position="top center"
        size="mini"
      />
    );
  },
  beforePan = function (oldPan, { x, y }) {
    const { width, height, realZoom, viewBox } = this.getSizes(),
      xLimit = width - viewBox.width * realZoom,
      yLimit = height - viewBox.height * realZoom,
      leftLimit = Math.min(xLimit, 0),
      topLimit = Math.min(yLimit, 0),
      rightLimit = Math.max(xLimit, 0),
      bottomLimit = Math.max(yLimit, 0);
    return {
      x: Math.max(leftLimit, Math.min(rightLimit, x)),
      y: Math.max(topLimit, Math.min(bottomLimit, y)),
    };
  };

export default class VisualisationImageSVG extends PureComponent {
  state = { tooltips: [], error: false };

  constructor(props) {
    super(props);
    const { id, name, selectedOutcomes, width, quality } = props;
    this.svgRef = React.createRef();
    this.src = appendQueryParams(`/${id}/${name || 0}.svg`, {
      outcomes: selectedOutcomes ? selectedOutcomes.join(",") : "0",
      width,
      quality,
    });
  }

  getSVGNode = () => {
    const refNode = this.svgRef && this.svgRef.current;
    if (refNode) {
      const [svgNode] = refNode.children;
      return svgNode && svgNode.tagName === "svg" ? svgNode : null;
    }
    return null;
  };

  changeThresholds = (svgNode, { heatmap, boundingBox }) => {
    for (let [featureName, threshold] of Object.entries(heatmap || {})) {
      const tableValues = getStepTableValues(threshold);
      for (let filterNode of svgNode.getElementsByClassName(
        `filter-${featureName}`
      )) {
        filterNode.setAttribute("tableValues", tableValues);
      }
    }
    for (let [featureName, threshold] of Object.entries(boundingBox || {})) {
      for (let scoreNode of svgNode.getElementsByClassName(
        `score-${featureName}`
      )) {
        scoreNode.style.visibility =
          scoreNode.getAttribute("data-score") >= threshold
            ? "inherit"
            : "hidden";
      }
    }
  };

  toggleOutcomes = (outcomeNodes, selectedOutcomes) => {
    for (let outcomeNode of outcomeNodes) {
      outcomeNode.style.visibility = selectedOutcomes.reduce(
        (visibility, selectedOutcome) =>
          outcomeNode.classList.contains(`outcome-${selectedOutcome}`)
            ? "visible"
            : visibility,
        "hidden"
      );
    }
  };

  setTooltips = (svgNode) => {
    const outcomeNodes = svgNode.getElementsByClassName("outcome"),
      tooltips = [];
    for (let outcomeNode of outcomeNodes) {
      const boxesCollection = outcomeNode.getElementsByClassName(
        "visualisation-score"
      );
      tooltips.push(...boxesCollection);
    }
    this.setState({ tooltips: [...tooltips] });
  };

  handleSVGLoaded = () => {
    const svgNode = this.getSVGNode(),
      { interactive } = this.props;
    if (svgNode) {
      if (interactive) {
        svgPanZoom(svgNode, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          mouseWheelZoomEnabled: true,
          preventMouseEventsDefault: true,
          zoomScaleSensitivity: 0.2,
          minZoom: 1,
          maxZoom: 10,
          fit: true,
          center: true,
          refreshRate: "auto",
          beforePan,
          eventsListenerElement: null,
        });
        this.setTooltips(svgNode);
      }
    } else {
      this.setState({ error: true });
    }
  };

  componentWillUnmount() {
    this.svgRef = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { dynamicThresholds, selectedOutcomes } = this.props,
      svgNode = this.getSVGNode();

    if (svgNode) {
      const outcomeNodes = svgNode.getElementsByClassName("outcome");
      if (selectedOutcomes !== prevProps.selectedOutcomes) {
        this.toggleOutcomes(outcomeNodes, selectedOutcomes);
      } else if (dynamicThresholds !== prevProps.dynamicThresholds) {
        this.changeThresholds(svgNode, dynamicThresholds);
      }
    }
  }

  render() {
    const {
        id,
        name,
        dynamicThresholds,
        selectedOutcomes,
        interactive,
        width,
        quality,
        static_token,
        ...props
      } = this.props,
      { tooltips, error } = this.state;

    console.log(this.src);
    return (
      <Image
        className={classNames("VisualisationImage", { interactive })}
        {...props}
      >
        {error ? (
          <Icon name="warning" size="huge" />
        ) : (
          <Fragment>
            <ImageSVG
              src={this.src}
              static_token={static_token}
              ref={this.svgRef}
              onSVGLoaded={this.handleSVGLoaded}
            />
            {tooltips.map((trigger, key) => (
              <SVGTooltip key={key} trigger={trigger} />
            ))}
          </Fragment>
        )}
      </Image>
    );
  }
}
