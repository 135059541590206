import React from "react";
import Routes from "./components/Routes";

import { AuthContextProvider } from "./contexts/AuthContext";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga";

const App = () => {
  ReactGA.initialize("UA-47724562-6");
  return (
    <div className="App">
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
      <ToastContainer />
    </div>
  );
};

export default App;
