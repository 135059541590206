import React, { Fragment, useContext, useState, useEffect } from "react";
import { Segment, Grid, Header, Icon, List } from "semantic-ui-react";
import { useParams, Link } from "react-router-dom";
import useWorkspace from "../hooks/useWorkspace";
import { AuthContext } from "../contexts/AuthContext";
import WorkspaceAdminPanel from "./WorkspaceAdminPanel";
import { SITE_URL } from "../utils/get_env";
import { toast } from "react-toastify";
import { useClipboard } from "use-clipboard-copy";
import WorkspaceStudiesPanel from "./Workspace/WorkspaceStudiesPanel";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/misc";

const Workspace = () => {
  let { workspace_id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [authState, authDispatch] = useContext(AuthContext);

  // Force reload
  const ws = useWorkspace(workspace_id, true);

  const clipboard = useClipboard();

  console.log("workspace_reload");

  useEffect(() => {
    setLoading(authState.workspaceUpdating);
  }, [authState]);

  if (!authState.currentWorkspace) return null;
  return (
    <Fragment>
      {authState.currentWorkspace.archived && (
        <Segment inverted color="yellow" icon="lock">
          <Icon name="lock" />
          This workspace is archived and no longer accepting new studies. You can still browse existing studies, export data and print reports.
        </Segment>
      )}

      <Segment padded="very" raised loading={loading}>
        <Header as="h1" dividing>
          <Icon className="MainIcon" name="desktop" />
          <Header.Content>
            {authState.currentWorkspace.name}
            <Header.Subheader>{capitalize(t("workspace"))}</Header.Subheader>
          </Header.Content>
        </Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h5" content={t("Description")} />
              {authState.currentWorkspace.description}
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5" content={t("Contents")} style={{ marginBottom: "0px" }} />
              <List>
                <List.Item
                  icon="user"
                  content={`${authState.currentWorkspace.users.length} ${t("user", {
                    count: authState.currentWorkspace.users.length,
                  })}`}
                />
                <List.Item
                  icon="clipboard outline"
                  content={`${authState.currentWorkspace.studies_count} ${t("study", {
                    count: authState.currentWorkspace.studies_count,
                  })}`}
                />
              </List>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={12}>
              <Header as="h5" content="URL" style={{ marginBottom: "0px" }} />
              <a href={`${SITE_URL}/ws/${authState.currentWorkspace.id}/`}>
                {`${SITE_URL}/ws/${authState.currentWorkspace.id}/`}
              </a>
              &nbsp;&nbsp;
              <Icon
                name="copy outline"
                onClick={() => {
                  clipboard.copy(
                    `${SITE_URL}/ws/${authState.currentWorkspace.id}/`
                  );
                  toast.info("Link to workshop copied to clipboard");
                }}
              />
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={12}>
              <Header as="h5" content="Actions" style={{ marginBottom: "0px" }} />
              {!authState.currentWorkspace.archived && <Link to={`/ws/${workspace_id}/new`}>{t("New Study")}</Link>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      {authState.userInformation.super_admin && <WorkspaceAdminPanel />}
      <WorkspaceStudiesPanel />
    </Fragment>
  );
};

export default Workspace;
