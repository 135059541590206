import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Popup, Table } from "semantic-ui-react";

import { aiErrors, aiWarnings } from "../../../config.json";
// import { outcomeShape } from "../../../types/outcome";
import TableThreshold from "./Threshold";
import AnalysisOutcome from "../Outcome";
import { capitalize } from "../../../utils/misc";
import { useTranslation } from "react-i18next";

const TableRow = ({ active, outcome, onChangeThreshold, onToggleOutcome }) => {

  const { t, i18n } = useTranslation();

  const handleToggleOutcome = () => {
    onToggleOutcome(outcome.name);
  };

  const disabled = Boolean(
    outcome.errors.length > 0 ||
    !outcome.visualisations ||
    outcome.visualisations.length === 0
  );

  const TableRowError = ({ type }, key) => (
    <em className="TableRow-error" key={key}>
      {aiErrors[type]}
    </em>
  );

  const TableRowDesc = (props) => {
    const content = (
      <Table.Cell className="TableRow-desc" textAlign="right" {...props}>
        {outcome.warnings.length > 0 && (
          <Icon
            className="TableRow-warning"
            name="warning sign"
            color="yellow"
          />
        )}
        {t(outcome.desc)}
      </Table.Cell>
    );
    return outcome.warnings.length > 0 ? (
      <Popup
        trigger={content}
        content={outcome.warnings.map(({ type }) => aiWarnings[type])}
      />
    ) : (
      content
    );
  }

  const dynamicFeatures = outcome.visualisations.reduce(
    (features, { defaultThreshold, featureName, objects }) => {
      const dynamicType =
        !features.find((feature) => feature.featureName === featureName) &&
        objects.reduce(
          (type, object) =>
            type ||
            (["boundingBox", "heatmap"].includes(object.type) &&
              object.type),
          false
        );
      return dynamicType
        ? [...features, { defaultThreshold, featureName, dynamicType }]
        : features;
    },
    []
  );


  return (
    <Fragment>
      <Table.Row
        className={`TableRow TableRow-${outcome.name}`}
        negative={Boolean(outcome.errors.length)}
        warning={Boolean(outcome.warnings.length)}
      >
        <TableRowDesc width={4} rowSpan={dynamicFeatures.length + 1} />
        <Table.Cell className="TableRow-outcome" textAlign="left" width={6}>
          {outcome.errors.length ? (
            outcome.errors.map(TableRowError)
          ) : (
            <AnalysisOutcome outcome={outcome} />
          )}
        </Table.Cell>
        <Table.Cell width={2} className="TableRow-toggle" textAlign="center">
          {outcome.errors.length > 0 ? (
            <Button icon="warning" disabled={disabled} size="tiny" />
          ) : (
            <Button
              icon={disabled ? "eye slash" : "eye"}
              toggle={true}
              disabled={disabled}
              onClick={handleToggleOutcome}
              size="tiny"
              style={{
                backgroundColor: active && (outcome.color || "#009688"),
                color: active && "white",
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
      {
        dynamicFeatures.map(
          ({ defaultThreshold, featureName, dynamicType }, key) => (
            <TableThreshold
              key={key}
              name={featureName}
              threshold={defaultThreshold}
              onChange={(e, { value }) =>
                onChangeThreshold(featureName, dynamicType, value)
              }
            />
          )
        )
      }
    </Fragment >
  );
}


// TableRow.propTypes = {
//   active: PropTypes.bool,
//   outcome: outcomeShape.isRequired,
//   onToggleOutcome: PropTypes.func.isRequired
// };

export default TableRow;
