import React, { PureComponent } from "react";
import { Icon, Label } from "semantic-ui-react";
import {
  Crosshair,
  FlexibleWidthXYPlot,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
} from "react-vis";
// import DebouncedInput from "../DebouncedInput";

import "./index.scss";

const CHART_HEIGHT = 150,
  CHART_MARGIN = { left: 17, right: 7, top: 10, bottom: 25 },
  CHART_GUTTER = 10,
  THRESHOLD_COLOR = "#FFCDD2";

class Chart extends PureComponent {
  render() {
    const {
        formatX,
        onChangeX,
        series,
        selectedX,
        xMin,
        xMax,
        height,
        margin,
        gutter,
        ...props
      } = this.props,
      xMaxSeries = isNaN(xMax)
        ? series.reduce(
            (max, { data }) =>
              data.reduce((xMaxPlot, { x }) => Math.max(xMaxPlot, x), max),
            0
          )
        : xMax,
      xMinSeries = isNaN(xMin)
        ? series.reduce(
            (min, { data }) =>
              data.reduce((xMinPlot, { x }) => Math.min(xMinPlot, x), min),
            xMaxSeries
          )
        : xMin,
      marginSeries = margin ? { ...CHART_MARGIN, ...margin } : CHART_MARGIN,
      gutterSeries = gutter || CHART_GUTTER,
      heightSeries = series.length
        ? Math.max(
            ((height || CHART_HEIGHT) -
              marginSeries.top -
              marginSeries.bottom -
              gutterSeries * (series.length - 1)) /
              series.length,
            10
          )
        : 0,
      labelsMargin = series.length ? (series.length - 1) * 10 : 0;
    return (
      <div className="Chart">
        {/* <DebouncedInput
          className="Chart-input"
          delay={100}
          fluid={true}
          min={xMinSeries}
          max={xMaxSeries}
          onChangeValue={onChangeX}
          type="range"
          defaultValue={selectedX}
          style={{ paddingLeft: labelsMargin }}
        /> */}
        <span className="Chart-YAxisTitle" style={{ left: labelsMargin }}>
          {series.map(({ color, id, title }, index) => {
            const icon = <Icon name="circle" style={{ color }} />;
            return (
              <Label
                basic={true}
                icon={icon}
                size="mini"
                key={`${id}-${index}`}
                content={title}
              />
            );
          })}
        </span>
        {series.map(({ data, color, id, title, threshold, units }, index) => {
          const point = data.find(({ x }) => x === selectedX),
            yMaxSerie = data.reduce(
              (yMaxPlot, { y }) => Math.max(yMaxPlot, y),
              1
            ),
            yMinSerie = data.reduce(
              (yMinPlot, { y }) => Math.min(yMinPlot, y),
              0
            ),
            isFirst = index === 0,
            isLast = index === series.length - 1,
            marginSerie = {
              ...marginSeries,
              left: marginSeries.left + labelsMargin,
              top: isFirst ? marginSeries.top : gutterSeries / 2,
              bottom: isLast ? marginSeries.bottom : gutterSeries / 2,
            },
            heightSerie = heightSeries + marginSerie.top + marginSerie.bottom,
            icon = <Icon name="circle" style={{ color }} />;
          return (
            <FlexibleWidthXYPlot
              key={`${id}-${index}`}
              dontCheckIfEmpty={true}
              xDomain={[xMinSeries, xMaxSeries]}
              yDomain={[yMinSerie, yMaxSerie]}
              height={heightSerie}
              margin={marginSerie}
              {...props}
            >
              <VerticalGridLines />
              <XAxis tickFormat={formatX} hideTicks={!isLast} />
              <HorizontalGridLines />
              <YAxis hideTicks={true} />
              <LineSeries key={`${id}-${index}`} data={data} color={color} />
              <Crosshair values={[{ x: selectedX }]}>
                <Label
                  icon={icon}
                  size="mini"
                  key={`${id}-${index}`}
                  content={point.y}
                  detail={units}
                />
              </Crosshair>
              {threshold ? (
                <LineSeries
                  key={id}
                  data={[
                    { x: xMinSeries, y: threshold },
                    { x: xMaxSeries, y: threshold },
                  ]}
                  color={THRESHOLD_COLOR}
                />
              ) : null}
            </FlexibleWidthXYPlot>
          );
        })}
      </div>
    );
  }
}

export default Chart;
