import React, { Fragment, PureComponent } from "react";
// import PropTypes from "prop-types";

import "./index.scss";

// import { fileShape } from "../../../types/file";
import VisualisationImage from "./ImageSVG";

class VisualisationProjection extends PureComponent {
  render() {
    const {
        file,
        selectedOutcomes,
        selectedSlice,
        totalSlices,
        ...props
      } = this.props,
      indicatorHeight = 175 / totalSlices,
      yIndicator = indicatorHeight * selectedSlice,
      midIndicator = yIndicator + indicatorHeight / 2;
    return (
      <Fragment>
        <VisualisationImage
          id={file.id}
          name="projection"
          selectedOutcomes={selectedOutcomes}
          {...props}
        />
        <svg
          className="VisualisationProjection-indicator"
          viewBox={`0 0 175 175`}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0"
            y={yIndicator}
            width="100%"
            height={indicatorHeight}
            vectorEffect="non-scaling-stroke"
          />
          <polygon
            points={`0 ${midIndicator - 8} 12 ${midIndicator} 0  ${
              midIndicator + 8
            }`}
            vectorEffect="non-scaling-stroke"
          />
          <polygon
            points={`175 ${midIndicator - 8} 163 ${midIndicator} 175 ${
              midIndicator + 8
            }`}
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </Fragment>
    );
  }
}

// VisualisationProjection.propTypes = {
//   file: fileShape.isRequired,
//   selectedOutcomes: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

export default VisualisationProjection;
