import React from "react";
import { Label, Icon, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const StatusLabel = ({ status }) => {
  const { t, i18n } = useTranslation();
  switch (status) {
    case "queued":
      return (
        <Label color="grey">
          {t("Status")}
          <Label.Detail>{("Queued for processing")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="spinner" loading />
        </Label>
      );

    case "processing":
      return (
        <Label color="purple">
          {t("Status")}
          <Label.Detail>{t("Processing")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="spinner" loading />
        </Label>
      );

    case "error":
      return (
        <Label color="red">
          {t("Status")}
          <Label.Detail>{t("Processing error")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="close" />
        </Label>
      );

    case "done":
      return (
        <Label basic color="green" size="small">
          {t("Status")}
          <Label.Detail>{t("Processing complete")} &nbsp; &nbsp;</Label.Detail>
          <Icon name="check" />
        </Label>
      );

    default:
      return (
        <Label basic color="grey" size="small">
          {t("Status")}
          <Label.Detail>{status} &nbsp; &nbsp;</Label.Detail>
          {/* <Icon name="check" /> */}
        </Label>
      );
  }
};

export default StatusLabel;
