import React, { PureComponent } from "react";

import "./index.scss";

import OutcomeResult from "./Result";

class AnalysisOutcome extends PureComponent {
  render() {
    const { outcome, ...props } = this.props;
    return outcome.results.map((result) => {
      const customOutcome = { ...result, ...props };
      return (
        <OutcomeResult
          className={`AnalysisOutcome AnalysisOutcome-${result.id}`}
          key={result.id}
          single={outcome.results.length === 1}
          {...customOutcome}
        />
      );
    });
  }
}

// AnalysisOutcome.propTypes = {
//   outcome: outcomeShape.isRequired
// };

export default AnalysisOutcome;
