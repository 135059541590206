import axios from "axios";
import authHeader from "./auth-header";

import { API_URL } from "../utils/get_env";

import { updateWorkspace } from "../utils/updateWorkspace";

const checkAnalysisCompleted = (analysisObject) => {
  // console.log(analysisObject);
  if (analysisObject.status === "done" || analysisObject.status === "error") return true;
  else return false;
};

// Function for long-polling
export const poll = (fn, fn_condition, timeout, interval, fn_update, fn_timeout) => {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = (resolve, reject) => {
    let ajax = fn();
    ajax.then((response) => {
      if (fn_condition(response.data)) {
        resolve(response.data);
      } else if (Number(new Date()) < endTime) {
        fn_update(response.data);
        setTimeout(checkCondition, interval, resolve, reject);
      } else {
        fn_timeout(fn);
      }
    });
  };
  return new Promise(checkCondition);
};

const pollingTimeout = (pollFn) => {
  console.log("time out!");
  console.log(pollFn);
};

const postStudy = (workspace_id, metadata) => {
  return axios
    .post(
      `${API_URL}/workspaces/${workspace_id}/studies`,
      {
        metadata: metadata,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const postAnalysis = (
  workspace_id,
  study_id,
  // file,
  tags,
  // filename,
  input_item_id
) => {
  let formData = new FormData();
  // formData.append("image", file, filename);
  formData.append("tags", tags);
  formData.append("input_item_id", input_item_id);

  console.log(input_item_id);

  return axios
    .post(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}/analyses/`, formData, {
      headers: authHeader(),
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log(percentCompleted);
      },
    })
    .then((response) => {
      return response.data;
    });
};

const pollAnalysis = (analysis_id, dispatch, filename, unsubscribeAnalysis) => {
  poll(
    () => {
      return axios.get(`${API_URL}/analyses/${analysis_id}/`, {
        headers: authHeader(),
      });
    },
    checkAnalysisCompleted,
    300000,
    2000,
    (res) => {
      dispatch({
        type: "setAnalysisStatus",
        filename: filename,
        status: res.status,
      });
    },
    pollingTimeout
  ).then((res) => {
    dispatch({
      type: "setAnalysisStatus",
      filename: filename,
      status: res.status,
    });
    unsubscribeAnalysis(res.id);
  });
};

const getStudy = (workspace_id, study_id, static_token) => {
  const headers = static_token ? { Authorization: `Bearer ${static_token}` } : authHeader();

  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}`, {
      headers: headers,
    })
    .then((response) => {
      return response.data;
    });
};

const getStudyConsult = (study_id, static_token) => {
  const headers = static_token ? { Authorization: `Bearer ${static_token}` } : authHeader();

  return axios
    .get(`${API_URL}/consult-study/${study_id}/`, {
      headers: headers,
    })
    .then((response) => {
      return response.data;
    });
};

const postQuality = (workspace_id, file, filename, dispatch) => {
  let formData = new FormData();
  formData.append("input_file", file, filename);

  return axios
    .post(`${API_URL}/workspaces/${workspace_id}/inputs/`, formData, {
      headers: authHeader(),
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        dispatch({
          type: "updateUploadItemUploadProgress",
          filename: filename,
          progress: percentCompleted,
        });
        // console.log(percentCompleted);
      },
    })
    .then((response) => {
      dispatch({
        type: "setAnalysisStatus",
        filename: filename,
        status: "uploaded",
      });
      return response.data;
    });
};

const getReport = (workspace_id, study_id, template_name) => {
  const headers = authHeader();
  headers["Accept-Language"] = "vi";
  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}/report/${template_name}`, {
      headers: headers,
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    });
};

const getHumanReport = (workspace_id, study_id, template_name) => {
  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}/reporting/labelbox-report`, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    });
};

const getIntegratonStatus = (workspace_id, study_id, integration) => {
  return axios
    .get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}/reporting/${integration}/status`, {
      headers: authHeader(),
    })
    .then((response) => {
      // console.log(response);
      return response.data;
    });
};

const getImage = (path, static_token) => {
  console.log(`${API_URL}/images${path}/`);
  const headers = static_token ? { Authorization: `Bearer ${static_token}` } : authHeader();

  return axios
    .get(`${API_URL}/images${path}/`, {
      headers: headers,
    })
    .then((response) => {
      return response.data;
    });
};

const checkAllAnalysesCompleted = (studyObject) => {
  const completedStatuses = ["uploading", "queued", "processing"];

  let anyNonCompleted = false;

  studyObject.analyses.forEach((analysis) => {
    if (completedStatuses.includes(analysis.status)) {
      anyNonCompleted = true;
    }
  });

  return !anyNonCompleted;
};

const pollStudy = (workspace_id, study_id, dispatchStudy, static_token) => {
  poll(
    () => {
      if (!static_token)
        return axios.get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}`, {
          headers: authHeader(),
        });
      else {
        return axios.get(`${API_URL}/workspaces/${workspace_id}/studies/${study_id}`, {
          headers: { Authorization: `Bearer ${static_token}` },
        });
      }
    },
    checkAllAnalysesCompleted,
    300000,
    2000,
    (res) => {
      res.analyses.map((analysis) => {
        if (["error", "done"].includes(analysis.status))
          dispatchStudy({
            type: "setAnalysis",
            id: analysis.id,
            analysis: analysis,
          });
        else
          dispatchStudy({
            type: "updateAnalysisStatus",
            id: analysis.id,
            status: analysis.status,
          });
      });
    },
    pollingTimeout
  ).then((res) => {
    res.analyses.map((analysis) => {
      dispatchStudy({
        type: "setAnalysis",
        id: analysis.id,
        analysis: analysis,
      });
    });
  });
};

// deleting study, CSAI-77, CSAI-78
const deleteStudy = (workspace_id, study_id) => {
  return axios
    .delete(`${API_URL}/workspaces/${workspace_id}/${study_id}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const refreshWorkspaceData = (workspace_id, dispatchFn) => {
  updateWorkspace(workspace_id, null, dispatchFn);
};

export default {
  postStudy,
  postAnalysis,
  pollAnalysis,
  pollStudy,
  getStudy,
  checkAllAnalysesCompleted,
  getReport,
  getHumanReport,
  getImage,
  getStudyConsult,
  postQuality,
  getIntegratonStatus,
  deleteStudy,
  refreshWorkspaceData,
};
