import React, { Fragment, useEffect, useState, useContext } from "react";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { Segment, Button, Header, Icon, Label, Grid, List, Tab, Table, Message } from "semantic-ui-react";
import UploadService from "../services/upload.service";
import AnalysisItem from "./AnalysisItem";
import { format } from "date-fns";
import { useClipboard } from "use-clipboard-copy";
import { SITE_URL } from "../utils/get_env";
import { toast } from "react-toastify";
import VerboseOutcomes from "./VerboseOutcomes";
import useWorkspace from "../hooks/useWorkspace";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/misc";
import { AuthContext } from "../contexts/AuthContext";
import LabelboxReport from "./Study/LabelboxReport";
import { subscribeToStudyUpdates, subscribeToAnalysisFromId } from "../services/websocket.service";

const studyReducer = (state, action) => {
  switch (action.type) {
    case "setStudyObject":
      return {
        ...state,
        analyses: action.payload.analyses,
        created_by: action.payload.created_by,
        created: action.payload.created,
        id: action.payload.id,
        workspace: action.payload.workspace,
        summary_verbose_outcomes: action.payload.verbose_outcomes,
        study_metadata: action.payload.study_metadata,
        integration_data: action.payload.integration_data,
        protocol_details: action.payload.protocol_details,
        overviews: action.payload.overviews,
      };

    case "updateStudyObject":
      return {
        ...state,
        summary_verbose_outcomes: action.study.verbose_outcomes,
      };

    case "setAnalysisImage":
      return {
        ...state,
        analyses: state.analyses.map((item) => (item.id === action.id ? { ...item, tmp_src: action.payload } : item)),
      };

    case "updateIntegrationStatus":
      if (state.study_integrations[action.name] && state.study_integrations[action.name].status === action.status) {
        return state;
      } else {
        state.study_integrations[action.name].status = action.status;
        return { ...state };
      }

    case "updateAnalysisStatus":
      if (state.analyses.filter((item) => item.id === action.id)[0].status === action.status) {
        return state;
      } else
        return {
          ...state,
          analyses: state.analyses.map((item) => (item.id === action.id ? { ...item, status: action.status } : item)),
        };

    case "setAnalysis":
      return {
        ...state,
        analyses: state.analyses.map((item) => (item.id === action.id ? action.analysis : item)),
      };

    case "setDone":
      return {
        ...state,
        done: action.payload,
      };

    case "setReportURL":
      return {
        ...state,
        reportURL: action.payload,
      };

    default:
      console.log(`Warning, default case reached in StudyReducer (action=${action.type})`);
      return state;
  }
};

const Study = () => {
  const initialStudyState = { analyses: [], done: false, reportURL: null };
  const [studyState, dispatchStudy] = React.useReducer(studyReducer, initialStudyState);
  const { t, i18n } = useTranslation();
  let { workspace_id, study_id } = useParams();
  let ws = useWorkspace(workspace_id); // Used to make sure current workspace is updated
  const [loading, setLoading] = useState(true);

  const [reportLoading, setReportLoading] = useState(false);
  const [authState, authDispatch] = useContext(AuthContext);

  const [overallError, setOverallError] = useState(false);

  let location = useLocation();
  const clipboard = useClipboard();

  let history = useHistory();

  // deleting study, CSAI-77, CSAI-78
  const handleStudyRemoval = (workspace_id, study_id) => {
    setLoading(true);
    UploadService.deleteStudy(workspace_id, study_id).then((data) => {
      if (data === "OK") {
        handleRefresh(workspace_id);
        toast.success(`Successfully removed Study from Workspace`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history.push(`/ws/${workspace_id}/`);
      } else {
        toast.error(`Error deleting study`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const handleRefresh = (workspace_id) => {
    UploadService.refreshWorkspaceData(workspace_id, authDispatch);
  };

  // Test for deployment demo
  useEffect(() => {
    setLoading(true);
    UploadService.getStudy(workspace_id, study_id).then((studyObject) => {
      dispatchStudy({ type: "setStudyObject", payload: studyObject });
      setLoading(false);

      // If image data from the new study form is available, we use that instead of waiting for a thumbnail to be generated
      if (location.state && location.state.uploadInputs) {
        location.state.uploadInputs.map((uploadInput) => {
          dispatchStudy({
            type: "setAnalysisImage",
            id: uploadInput.analysisId,
            payload: URL.createObjectURL(uploadInput.imageFile),
          });
        });
      }

      if (UploadService.checkAllAnalysesCompleted(studyObject)) {
        dispatchStudy({
          type: "setDone",
          payload: true,
        });
      } else {
        subscribeToStudyUpdates(studyObject.id, dispatchStudy);
        studyObject.analyses.forEach((analysis) => {
          subscribeToAnalysisFromId(analysis.id, dispatchStudy);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (UploadService.checkAllAnalysesCompleted(studyState) && studyState.analyses.length) {
      if (!studyState.done) {
        dispatchStudy({
          type: "setDone",
          payload: true,
        });
      }
    }
  }, [studyState]);

  // Check if any error was reported
  useEffect(() => {
    // if (studyState.overviews) {
    // const outcomeOverview = studyState.overviews.filter((overview) => overview.name === "outcome");
    // if (outcomeOverview && outcomeOverview.value === "Error") {
    //   setOverallError(true);
    // }
    // }
    studyState.analyses.forEach((analysis) => {
      if (analysis.ai_outcomes) {
        analysis.ai_outcomes.forEach((outcome) => {
          if (outcome.errors.length > 0) {
            console.log("ohno");
            setOverallError(true);
          }
        });
      }
    });
  }, [studyState]);

  return (
    <Fragment>
      <Segment padded="very" raised loading={loading}>
        <Header as="h1" dividing>
          <Icon name="clipboard outline" />
          <Header.Content>
            {capitalize(t("study"))}
            <Header.Subheader>{capitalize(t("automated interpretation results"))}</Header.Subheader>
          </Header.Content>
        </Header>

        {authState.userInformation && authState.userInformation.super_admin && (
          <Button
            floated="right"
            onClick={() => {
              handleStudyRemoval(authState.currentWorkspace.id, studyState.id);
            }}
          >
            {capitalize(`${t("delete")} ${t("study")}`)}
          </Button>
        )}

        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h5" content={t("Created by")} />
              {studyState.created_by && studyState.created_by.email}
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5" content={t("Creation Date")} />
              {studyState.created && format(new Date(studyState.created), "yyyy-MM-dd HH:mm")}
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5" content={capitalize(t("workspace"))} style={{ marginBottom: "0px" }} />
              {studyState.workspace && <Link to={{ pathname: `/ws/${studyState.workspace.id}` }}>{studyState.workspace.name}</Link>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h5" content={t("Report")} style={{ marginBottom: "0px" }} />
              <List bulleted size={"small"} style={{ marginTop: "0px" }}>
                {authState.currentWorkspace &&
                  authState.currentWorkspace.protocol_details.report_templates.map((template) => (
                    <List.Item
                      as={Link}
                      onClick={() => {
                        setReportLoading(true);
                        let newTab = window.open(window.location.origin + "/report-temporary/", "_blank");
                        UploadService.getReport(workspace_id, study_id, template.name)
                          .then((data) => {
                            setReportLoading(false);

                            newTab.location.href = data.pdf;
                          })
                          .catch(
                            (error) =>
                              // console.log("COULD NOT DOWNLOAD PDF")
                              (newTab.location.href = window.location.origin + "/report-temporary/?error=true")
                          );
                      }}
                    >
                      {t(template.description)}
                    </List.Item>
                  ))}
                {/* 
                <Button
                  content={t("Open")}
                  size={"mini"}
                  primary
                  compact
                  disabled={!studyState.done}
                  loading={reportLoading}
                  onClick={() => {
                    setReportLoading(true);
                    let newTab = window.open(
                      window.location.origin + "/report-temporary/",
                      "_blank"
                    );
                    UploadService.getReport(workspace_id, study_id)
                      .then((data) => {
                        setReportLoading(false);

                        newTab.location.href = data.pdf;
                      })
                      .catch((error) => console.log("COULD NOT DOWNLOAD PDF"));
                  }}
                /> */}
              </List>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header as="h5" content={t("Unique Identifier")} style={{ marginBottom: "0px" }} />
              {studyState.id && (
                <Fragment>
                  <Link
                    to={{
                      pathname: `/ws/${studyState.workspace.id}/${studyState.id}`,
                    }}
                  >
                    {studyState.id}
                  </Link>
                  &nbsp;&nbsp;
                  <Icon
                    name="copy outline"
                    onClick={() => {
                      clipboard.copy(`${SITE_URL}/ws/${studyState.workspace.id}/${studyState.id}`);
                      toast.info("Link to study copied to clipboard");
                    }}
                  />
                </Fragment>
              )}
            </Grid.Column>
          </Grid.Row>

          <LabelboxReport studyState={studyState} dispatchStudy={dispatchStudy} workspace_id={workspace_id} study_id={study_id} />
          <Grid.Row>
            {studyState.study_metadata &&
              studyState.study_metadata.map((metadata) => (
                <Grid.Column width={4} key={metadata.name}>
                  <Header as="h5" content={t(metadata.name_verbose)} />
                  {metadata.value}
                </Grid.Column>
              ))}
          </Grid.Row>
        </Grid>
      </Segment>
      {authState.currentWorkspace &&
        (!authState.currentWorkspace.protocol_details.detailed_study_results_admin_only || authState.userInformation.super_admin) && (
          <Segment padded raised raised loading={loading}>
            <Header
              dividing={true}
              content={t("Detailed Study Results")}
              icon="chart bar outline"
              subheader={t("Overview and results for individual images")}
            />
            {overallError && (
              <Segment padded="very" raised loading={loading}>
                <Label attached="top left">{t("Overview")}</Label>
                {t("Summary of the main findings for all analyses included in this study")}:
                <Message
                  icon="warning"
                  error
                  header="Invalid Automated Interpretation"
                  content="Error for at least one image in this study, invalidating this automated interpretation. See below to find out which image(s) could
                    not be processed. Please contact support@cybersight.ai for further assistance."
                ></Message>
              </Segment>
            )}
            {studyState.summary_verbose_outcomes && !overallError && (
              <Segment padded="very" raised loading={loading}>
                <Label attached="top left">{t("Overview")}</Label>
                {t("Summary of the main findings for all analyses included in this study")}
                :
                <VerboseOutcomes status={studyState.done ? "done" : "processing"} outcomes={studyState.summary_verbose_outcomes} />
              </Segment>
            )}
            <Segment.Group piled>
              {studyState.analyses.map((analysis) => (
                <AnalysisItem key={analysis.id} analysis={analysis} />
              ))}
            </Segment.Group>
          </Segment>
        )}
    </Fragment>
  );
};

export default Study;
