import React, { useEffect, useState, useCallback, useReducer, Fragment, useContext } from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import { useParams, useHistory } from "react-router-dom";
import UploadService from "../../services/upload.service";
import { AuthContext } from "../../contexts/AuthContext";

import tagsHelper from "../../utils/tags_helper.js";
import NewStudyForm from "./NewStudyForm";

import useWorkspace from "../../hooks/useWorkspace";
import { subscribeToStudy, subscribeToAnalysis } from "../../services/websocket.service";
import { useTranslation } from "react-i18next";

const NewStudy = () => {
  let { workspace_id } = useParams();
  let ws = useWorkspace(workspace_id); // Used to make sure current workspace is updated
  const [authState, authDispatch] = useContext(AuthContext);

  const [inputSpecs, setInputSpecs] = useState(null);
  const [metadataSpecs, setMetadataSpecs] = useState(null);

  const [loading, setLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validatedInputSpecs, setValidatedInputSpecs] = useState(null);

  const SLOW_STUDY_THRESHOLD = 60000;

  const { t, i18n } = useTranslation();

  const initialState = {
    uploadInputs: [],
    studyStatus: null,
    studyId: null,
    studyReferenceCode: null,
    metadata: [],
    slow: false,
  };

  // Use another key than filename? What about using key-pairs instead of an array for easier indexing
  const studyReducer = (state, action) => {
    switch (action.type) {
      case "setInitialMetadata":
        action.metadata = action.metadata.map((item) => ({
          ...item,
          validated: false,
          content: "",
        }));

        return {
          ...state,
          metadata: action.metadata,
        };

      case "updateMetadata":
        // If the metadata entry does not exist, create it!
        if (!state.metadata.filter((item) => item.name === action.name).length) {
          state.metadata.push({
            name: action.name,
            content: action.content,
            validated: false,
          });
        } else {
          // If exists, update it!
          return {
            ...state,
            metadata: state.metadata.map((item) =>
              item.name === action.name
                ? {
                    ...item,
                    content: action.content,
                    validated: action.validated,
                  }
                : item
            ),
          };
        }

      case "setSlow":
        return {
          ...state,
          slow: true,
        };

      case "addUploadInput":
        action.item.modality = "fundus";

        return {
          ...state,
          uploadInputs: [...state.uploadInputs, action.item],
        };

      case "deleteUploadItem":
        return {
          ...state,
          uploadInputs: state.uploadInputs.filter((item) => item.filename !== action.filename),
        };

      case "updateUploadItemTags":
        return {
          ...state,
          uploadInputs: state.uploadInputs.map((item) =>
            item.filename === action.filename
              ? {
                  ...item,
                  tags: action.tags,
                }
              : item
          ),
        };

      case "updateUploadItemImage":
        return {
          ...state,
          uploadInputs: state.uploadInputs.map((item) =>
            item.filename === action.filename
              ? {
                  ...item,
                  imageDataURL: action.imageDataURL,
                  imageFile: action.imageFile,
                }
              : item
          ),
        };

      case "updateUploadItemQA":
        console.log(action.gradable);
        return {
          ...state,
          uploadInputs: state.uploadInputs.map((item) =>
            item.filename === action.filename
              ? {
                  ...item,
                  gradable: action.gradable,
                  verified: action.verified,
                  laterality: action.laterality,
                  fov: action.fov,
                  input_item_id: action.input_item_id,
                }
              : item
          ),
        };

      case "updateUploadItemUploadProgress":
        return {
          ...state,
          uploadInputs: state.uploadInputs.map((item) =>
            item.filename === action.filename
              ? {
                  ...item,
                  uploadProgress: action.progress,
                }
              : item
          ),
        };

      case "setStudyId":
        return {
          ...state,
          studyId: action.studyId,
        };

      case "setAnalysisId":
        return {
          ...state,
          uploadInputs: state.uploadInputs.map((item) =>
            item.filename === action.filename
              ? {
                  ...item,
                  analysisId: action.analysisId,
                }
              : item
          ),
        };

      case "setAnalysisStatus":
        // Avoid re-rendering the whole component if status hasn't changed
        const old_status = state.uploadInputs.filter((item) => item.filename === action.filename)[0].status;

        if (old_status === action.status) return state;
        else
          return {
            ...state,
            uploadInputs: state.uploadInputs.map((item) =>
              item.filename === action.filename
                ? {
                    ...item,
                    status: action.status,
                  }
                : item
            ),
          };

      case "setStudyStatus":
        return {
          ...state,
          studyStatus: action.studyStatus,
        };

      case "reset":
        return initialState;

      default:
        console.log(`Default case called!!! - Action: ${action}`);
        return state;
    }
  };

  let history = useHistory();

  const [studyState, dispatchStudy] = useReducer(studyReducer, initialState);
  const startStudyUpload = () => {
    let metadataForUpload = studyState.metadata.map((metadata) => ({
      name: metadata.name,
      value: metadata.content,
    }));

    dispatchStudy({
      type: "setStudyStatus",
      studyStatus: "started",
    });

    UploadService.postStudy(workspace_id, metadataForUpload)
      .then((result) => {
        setTimeout(() => {
          dispatchStudy({
            type: "setSlow",
          });
        }, SLOW_STUDY_THRESHOLD);

        subscribeToStudy(result.study_id);

        dispatchStudy({
          type: "setStudyId",
          studyId: result.study_id,
        });

        initiateInputUploads(result.study_id);
      })
      .catch(() => {
        dispatchStudy({
          type: "setStudyStatus",
          studyStatus: "error-upload",
        });
      });
  };

  useEffect(() => {
    let allProcessingDone = true;
    let allProcessingError = true;

    let count_done = 0;
    let count_error = 0;

    if (studyState.uploadInputs.length > 0) {
      studyState.uploadInputs.forEach((uploadInput) => {
        if (uploadInput.status === "done") count_done += 1;
        if (uploadInput.status === "error") count_error += 1;
      });

      if (count_done === studyState.uploadInputs.length) {
        allProcessingDone = true;
        allProcessingError = false;
      } else if (count_error + count_done === studyState.uploadInputs.length) {
        allProcessingDone = false;
        allProcessingError = true;
      } else {
        allProcessingDone = false;
        allProcessingError = false;
      }

      if ((allProcessingDone || allProcessingError) && studyState.studyStatus !== "done" && studyState.studyStatus !== "done-error") {
        if (!allProcessingError)
          dispatchStudy({
            type: "setStudyStatus",
            studyStatus: "done",
          });
        else
          dispatchStudy({
            type: "setStudyStatus",
            studyStatus: "done-error",
          });

        setTimeout(() => {
          history.push({
            pathname: `/ws/${workspace_id}/${studyState.studyId}/`,
            state: {
              uploadInputs: studyState.uploadInputs,
            },
          });
        }, 5000);
      }
    }
  }, [studyState]);

  const initiateInputUploads = (study_id) => {
    studyState.uploadInputs.forEach((uploadItem) => {
      // dispatchStudy({
      //   type: "setAnalysisStatus",
      //   filename: uploadItem.filename,
      //   status: "uploading",
      // });

      UploadService.postAnalysis(
        workspace_id,
        study_id,
        // uploadItem.imageFile,
        tagsHelper.tagsToAPIStr(uploadItem.tags),
        // uploadItem.filename,
        uploadItem.input_item_id
      )
        .then((result) => {
          subscribeToAnalysis(result.id, uploadItem.filename, dispatchStudy);

          dispatchStudy({
            type: "setAnalysisId",
            filename: uploadItem.filename,
            analysisId: result.id,
          });
          dispatchStudy({
            type: "setAnalysisStatus",
            filename: uploadItem.filename,
            status: result.status,
          });
        })
        .catch((error) => {
          dispatchStudy({
            type: "setAnalysisStatus",
            filename: uploadItem.filename,
            status: "error",
          });
        });
    });
  };

  const validateUploadInputs = useCallback(() => {
    const errors = [];

    let minInputs = 0;
    let maxInputs = 0;

    console.log(inputSpecs.inputs);

    inputSpecs.inputs.forEach((spec) => {
      if (spec.required) minInputs += spec.min_repeat;

      maxInputs += spec.max_repeat;
    });

    if (inputSpecs.min_inputs_override) minInputs = inputSpecs.min_inputs_override;
    if (inputSpecs.max_inputs_override) maxInputs = inputSpecs.max_inputs_override;

    // Check for length-related issues
    if (studyState.uploadInputs.length > maxInputs) {
      errors.push({
        type: "TOO_MANY_INPUTS",
        text: t("Maximum of {{maxImages}} inputs allowed (currently {{currentImages}} added to study).", {
          maxImages: maxInputs,
          currentImages: studyState.uploadInputs.length,
        }),
        resolution: `Remove at least ${studyState.uploadInputs.length - inputSpecs.inputs.length} inputs from study`,
      });
    }

    if (studyState.uploadInputs.length < minInputs) {
      errors.push({
        type: "TOO_FEW_INPUTS",
        text: t("Need at least {{minInputs}} inputs to submit study for analysis.", { minInputs: minInputs }),
        resolution: `Add at least one input to proceed`,
      });
    }

    // Tag Validation
    const validatedInputs = {
      inputs: [],
      minInputs: minInputs,
      maxInputs: maxInputs,
    };

    let nonValidatedUploadInputCount = 0;

    studyState.uploadInputs.forEach((uploadInput) => {
      let validatesSomething = false;
      // if (!uploadInput.verified)
      //   errors.push({
      //     type: "NON_VERIFIED_INPUTS",
      //     text: `Input image ${uploadInput.filename} not verified`,
      //     resolution: `Remove input ${uploadInput.filename} from study`,
      //   });

      inputSpecs.inputs.forEach((inputSpec) => {
        const validatedTags = [];

        inputSpec.parsed_tags.forEach((parsedTag) => {
          if (uploadInput.tags[parsedTag.name] && uploadInput.tags[parsedTag.name] === parsedTag.value) {
            validatedTags.push(parsedTag);
          }
        });

        if (validatedTags.length === inputSpec.parsed_tags.length) {
          validatedInputs.inputs.push(inputSpec.name);
          validatesSomething = true;
        }
      });

      if (!validatesSomething) nonValidatedUploadInputCount += 1;
    });

    console.log(nonValidatedUploadInputCount);

    if (nonValidatedUploadInputCount > 0 && inputSpecs.all_images_must_validate)
      errors.push({
        type: "NON_VALIDATED_IMAGES",
        text: `All images must be validated.`,
        resolution: `Make sure all images are validated`,
      });

    // List specs that do not match images
    const allSpecsName = inputSpecs.inputs.map((spec) => {
      return spec.name;
    });
    const nonValidatedInputNames = allSpecsName.filter((x) => !validatedInputs.inputs.includes(x));

    nonValidatedInputNames.forEach((specName) => {
      let nonValidSpec = inputSpecs.inputs.filter((spec) => spec.name === specName)[0];

      if (nonValidSpec.required) {
        errors.push({
          type: "SPEC_NOT_VALIDATED",
          text: `Input requires at least 1 instance.`,
          resolution: `Add at least one instance of ${nonValidSpec.name}`,
          input: nonValidSpec.name,
        });
      }
    });

    // Check for uniqueness of entries
    const duplicatedInputSpecs = validatedInputs.inputs.filter(
      (
        (s) => (v) =>
          s.has(v) || !s.add(v)
      )(new Set())
    );

    if (duplicatedInputSpecs) {
      duplicatedInputSpecs.forEach((duplicatedInput) => {
        let spec = inputSpecs.inputs.filter((input) => input.name === duplicatedInput)[0];

        let nRepeats = validatedInputs.inputs.filter((input) => input === duplicatedInput).length;

        if (nRepeats > spec.max_repeat) {
          errors.push({
            input: spec.name,
            type: "TOO_MANY_REPEATS",
            text: t("Input exceeds maximum number of repeats ({{maxRepeat}}).", { maxRepeat: spec.max_repeat }),
            resolution: `Remove ${spec.max_repeant - nRepeats} repeats of ${spec.name}`,
          });
        }
      });
    }

    validatedInputs.inputs.forEach((validatedInput) => {
      let spec = inputSpecs.inputs.filter((input) => input.name === validatedInput)[0];

      if (spec.validates_with.length && !validatedInputs.inputs.includes(spec.validates_with[0])) {
        let targetSpec = inputSpecs.inputs.filter((input) => input.name === spec.validates_with[0])[0];

        errors.push({
          input: spec.name,
          type: "VALIDATES_WITH",
          text: `${t("Requires the following to be added to study as well")}: ${targetSpec.parsed_tags.map((tag) => {
            return tag.value_text;
          })} `,
          resolution: ``,
        });
      }
    });
    console.log(validatedInputs);
    // Check for validation_with requirements

    console.log(errors);
    setValidationErrors(errors);
    setValidatedInputSpecs(validatedInputs);

    return errors;
  }, [inputSpecs, studyState]);

  useEffect(() => {
    if (studyState && inputSpecs) {
      validateUploadInputs();
    }
  }, [studyState, inputSpecs]);

  useEffect(() => {
    setValidationErrors([]);
    setValidatedInputSpecs({});

    dispatchStudy({
      action: "reset",
    });

    const newInputs = [];

    if (authState.currentWorkspace) {
      dispatchStudy({
        type: "setInitialMetadata",
        metadata: authState.currentWorkspace.protocol_details.metadata,
      });

      authState.currentWorkspace.protocol_details.inputs.forEach((input) => {
        newInputs.push({
          required_tags: input.required_tags,
          max_repeat: input.max_repeat,
          min_repeat: input.min_repeat,
          required: input.required,
          parsed_tags: tagsHelper.parseTagsFromAPI(input.required_tags, tagsHelper.possibleTags),
          modality: input.modality,
          name: input.name,
          validates_with: input.validates_with,
        });

        setInputSpecs({
          inputs: newInputs,
          min_inputs_override: authState.currentWorkspace.protocol_details.min_inputs,
          max_inputs_override: authState.currentWorkspace.protocol_details.max_inputs,
          all_images_must_validate: authState.currentWorkspace.protocol_details.all_images_must_validate,
        });
        setLoading(false);
      });
    }
  }, [workspace_id, authState]);

  if (loading)
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  else
    return (
      <Fragment>
        <NewStudyForm
          possibleTags={tagsHelper.possibleTags}
          dispatchStudy={dispatchStudy}
          studyState={studyState}
          validatedInputSpecs={validatedInputSpecs}
          validationErrors={validationErrors}
          inputSpecs={inputSpecs}
          metadataSpecs={metadataSpecs}
          startStudyUpload={startStudyUpload}
        />
      </Fragment>
    );
};

export default NewStudy;
