import React, { PureComponent } from "react";
import { Label } from "semantic-ui-react";

import AnalysisOutcome from "./Outcome";

import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";

const GRADABILITY_OUTCOMES = [
  "fundus_overall_gradability",
  "fundus_macula_gradability",
  "fundus_disc_gradability",
  "fundus_retina_gradability",
  "oct_quality_score",
];

const AnalysisGradability = ({ outcomes, shortened, exclude, circular }) => {

  const { t, i18n } = useTranslation();

  return (
    <Label.Group className="AnalysisGradability">
      {outcomes &&
        GRADABILITY_OUTCOMES.map((outcomeName) =>
          outcomes.find(({ name }) => outcomeName === name)
        )
          .filter(Boolean)
          .map(
            (outcome) =>
              (!exclude || !exclude.includes(outcome.name)) && (
                <AnalysisOutcome
                  key={outcome.name}
                  outcome={outcome}
                  content={shortened ? t(outcome.desc[0]) : t(outcome.desc)}
                  circular={circular}
                />
              )
          )}
    </Label.Group>
  );
}

// TODO : PropTypes

export default AnalysisGradability;
