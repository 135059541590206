import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Header, Popup, Progress } from "semantic-ui-react";

import { formatValue, getGradientColor } from "../../../utils/analysis_helper";
import { useTranslation } from "react-i18next";

const OutcomeDetail = ({ title, labels, threshold, values, ...props }) => {

  console.log(labels)
  const { t, i18n } = useTranslation();

  const length = values.length - 1;
  const formattedThreshold = threshold && formatValue(threshold * 100);
  const content = (

    <Fragment>
      <Header content={t(title)} />
      {labels &&
        values &&
        values.map((value, index) => {
          const color = getGradientColor(index / length),
            percent = formatValue(value * 100);
          return (
            <Progress
              key={index}
              color={color}
              label={t(labels[index])}
              percent={percent}
              progress={true}
              size="small"
            />
          );
        })}
      {threshold && (
        <small className="OutcomeResult-threshold">
          Threshold : <b>{formattedThreshold}%</b>
        </small>
      )}
    </Fragment>
  );
  return <Popup {...props} content={content} style={{ zIndex: 10000 }} />;
}

OutcomeDetail.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default OutcomeDetail;
