import React, { Fragment, PureComponent } from "react";
// import PropTypes from "prop-types";
import { Card, Item, Menu } from "semantic-ui-react";

import "./index.scss";

// import { fileShape } from "../../../types/file";
import Chart from "../Chart";
import VisualisationImage from "./ImageSVG";
import VisualisationIndicator from "./Indicator";
import VisualisationProjection from "./Projection";
// import VisualisationVolume from "./Volume";

class AnalysisVisualisation extends PureComponent {
  constructor(props) {
    super(props);
    const {
      analysis: { maxIndex },
    } = props;
    this.state = {
      activeVisualisation: "slice",
      selectedSlice: Math.floor((maxIndex + 1) / 2),
    };
  }

  handleChangeSlice = (value) => this.setState({ selectedSlice: +value });

  handleChangeVisualisation = (e, { name }) =>
    this.setState({ activeVisualisation: name });

  render() {
    const {
        analysis,
        dynamicThresholds,
        selectedOutcomes,
        interactive,
        static_token,
        ...props
      } = this.props,
      { activeVisualisation, selectedSlice } = this.state,
      outcomes = analysis.outcomes.filter((outcome) =>
        selectedOutcomes.includes(outcome.name)
      ),
      sliceIndex =
        selectedSlice > analysis.maxIndex ? analysis.maxIndex : selectedSlice;

    return (
      <Fragment>
        {analysis.volume ? (
          <Menu
            className="AnalysisVisualisation-menu"
            tabular={true}
            size="small"
          >
            <Menu.Item
              name="slice"
              active={activeVisualisation === "slice"}
              onClick={this.handleChangeVisualisation}
            />
            <Menu.Item
              name="volume"
              active={activeVisualisation === "volume"}
              onClick={this.handleChangeVisualisation}
            />
          </Menu>
        ) : null}
        <Card
          fluid={true}
          className={`AnalysisVisualisation Visualisation-${analysis.modality}`}
          {...props}
        >
          {analysis.volume && activeVisualisation === "volume" ? (
            // <VisualisationVolume
            //   key={file.id}
            //   file={file}
            //   selectedOutcomes={selectedOutcomes}
            // />
            <div></div>
          ) : (
            <Fragment>
              <VisualisationImage
                id={analysis.id}
                key={`${analysis.id}-${selectedSlice}`}
                name={sliceIndex}
                dynamicThresholds={dynamicThresholds}
                selectedOutcomes={selectedOutcomes}
                interactive={interactive}
                static_token={static_token}
              />
              {analysis.maxIndex ? (
                <Fragment>
                  <VisualisationIndicator
                    outcomes={analysis.outcomes}
                    selectedSlice={sliceIndex}
                    totalSlices={analysis.maxIndex + 1}
                  />
                  <Card.Content as={Item.Group}>
                    <Item>
                      {analysis.projection ? (
                        <Item.Image>
                          <VisualisationProjection
                            analysis={analysis}
                            selectedOutcomes={selectedOutcomes}
                            selectedSlice={sliceIndex}
                            totalSlices={analysis.maxIndex + 1}
                          />
                        </Item.Image>
                      ) : null}
                      <Item.Content>
                        <Chart
                          formatX={(value) => value + 1}
                          onChangeX={this.handleChangeSlice}
                          series={outcomes.reduce(
                            (series, { results }) => [...series, ...results],
                            []
                          )}
                          selectedX={sliceIndex}
                          xMin={0}
                          xMax={analysis.maxIndex}
                        />
                      </Item.Content>
                    </Item>
                  </Card.Content>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </Card>
      </Fragment>
    );
  }
}

// AnalysisVisualisation.propTypes = {
//   file: fileShape.isRequired,
//   selectedOutcomes: PropTypes.arrayOf(PropTypes.string).isRequired
// };

export default AnalysisVisualisation;
