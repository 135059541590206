import React, { useState } from "react";
import { Input, Table } from "semantic-ui-react";

export default ({ name, threshold, onChange }) => {
  const [value, setValue] = useState(threshold || 0.0);
  return (
    <Table.Row className="TableThreshold">
      <Table.Cell>
        <Input
          fluid={true}
          min={0}
          max={1}
          step={0.01}
          onChange={(e, data) => {
            setValue(data.value);
            onChange && onChange(e, data);
          }}
          value={value}
          type="range"
          title="Threshold"
        />
      </Table.Cell>
      <Table.Cell>{Math.round(value * 100)}%</Table.Cell>
    </Table.Row>
  );
};
