import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Header, Menu } from "semantic-ui-react";
import { AuthContext } from "../contexts/AuthContext";
import "../index.scss";

const InitialWorkspaceSelectionMenu = () => {
  const [authState, authDispatch] = useContext(AuthContext);

  return (
    <Fragment>
      <Header size="small" content="Select a workspace" />
      <Menu className="OrganizationMenu" vertical>
        {authState.userInformation.workspaces.map((ws) => (
          <Menu.Item
            as={NavLink}
            key={ws.id}
            exact={true}
            to={`/ws/${ws.id}/`}
            className="SiteMenuItem"
            content={ws.name}
            icon="chevron right"
          />
        ))}
      </Menu>
    </Fragment>
  );
};

export default InitialWorkspaceSelectionMenu;
