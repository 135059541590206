import React, { useState, useContext, Fragment } from "react";
import {
  Form,
  Message,
  Segment,
  Image,
  Divider,
  Button,
} from "semantic-ui-react";

import AuthService from "../services/auth.service";

import { AuthContext } from "../contexts/AuthContext";

import logo from "../resources/Cybersight-AI.png";

import { API_URL } from "../utils/get_env";

const Admin = (props) => {
  const [authState, authDispatch] = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    AuthService.login(email, password)
      .then((userData) => {
        authDispatch({ type: "login" });
        props.history.push("/me");
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      });
  };

  return (
    <Fragment>
      <Segment basic>
        <Image size="large" src={logo} centered />
      </Segment>
      <Segment className="AuthenticationForm" padded raised>
        {message && <Message error={true} content={message} />}
        <Form loading={loading} onSubmit={handleLogin}>
          <Form.Input
            type="email"
            label="Email"
            placeholder="Email"
            fluid
            icon="user"
            iconPosition="left"
            name="email"
            onChange={onChangeEmail}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            label="Password"
            placeholder="Password"
            onChange={onChangePassword}
          />
          <Segment basic textAlign="center">
            <Form.Button primary={true} content="Login" type="submit" />
          </Segment>
        </Form>
        <Divider horizontal>Or</Divider>
        <Segment basic textAlign="center">
          <a href={API_URL + "/saml?sso"}>
            <Button primary={false} content="Login with Cybersight" />
          </a>
        </Segment>
      </Segment>
    </Fragment>
  );
};

export default Admin;
