import { useEffect, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { updateWorkspace } from "../utils/updateWorkspace";

const useWorkspace = (workspace_id, forceUpdate) => {
  const [authState, authDispatch] = useContext(AuthContext);

  useEffect(() => {
    if (
      !authState.currentWorkspace ||
      authState.currentWorkspace.id !== workspace_id ||
      forceUpdate
    ) {
      updateWorkspace(workspace_id, authState, authDispatch);
    }
  }, [workspace_id, forceUpdate]);

  return true;
};

export default useWorkspace;
