import React, { useState } from "react";
import { Container, Segment, Header, Icon, Grid, Label, Image, Button, Progress } from "semantic-ui-react";
import UploadTagForm from "./UploadTagForm";
import CropModal from "./CropModal";
import StatusLabelNewStudy from "./StatusLabelNewStudy";
import { useTranslation } from "react-i18next";

const InputImageItem = ({
  analysis: { filename, status, imageDataURL, verified, gradable, uploadProgress, laterality, fov },
  possibleTags,
  dispatchStudy,
  studyState,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Segment key={filename}>
      <Label attached="top">
        <Icon name="file" />
        {filename}
      </Label>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={3} verticalAlign={"middle"} textAlign="center">
            <Image src={imageDataURL} onClick={() => {}} centered />

            {/* hide crop functionality button */}
            {/* <CropModal
              src={imageDataURL}
              filename={filename}
              dispatchStudy={dispatchStudy}
            /> */}
          </Grid.Column>
          <Grid.Column width={9}>
            <Grid.Row>
              <Container style={{ minHeight: "25px" }}>
                <span style={{ float: "left" }}>
                  <StatusLabelNewStudy status={status} verified={verified} gradable={gradable} uploadProgress={uploadProgress} />
                </span>
                <Button
                  basic
                  compact
                  color="red"
                  disabled={studyState.studyStatus === "started" || studyState.studyStatus === "error"}
                  size={"tiny"}
                  style={{ float: "right" }}
                  onClick={(event, data) =>
                    dispatchStudy({
                      type: "deleteUploadItem",
                      filename: filename,
                    })
                  }
                >
                  <Icon name="trash alternate outline" />
                  {t("Remove from study")}
                </Button>
              </Container>
            </Grid.Row>
            <Segment loading={!(verified !== null && status != "error")} disabled={verified == false || verified == null || status == "error"}>
              <Header as="h5">{t("Image Tags")}</Header>
              <UploadTagForm
                possibleTags={possibleTags}
                filename={filename}
                dispatchStudy={dispatchStudy}
                studyState={studyState}
                laterality={laterality}
                fov={fov}
                disabled={verified == false || verified == null || status == "error"}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default InputImageItem;
