import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Label, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import TableRow from "./Row";

const TableGroup = ({ group, outcomes, selectedOutcomes, ...props }) => {

  const { t, i18n } = useTranslation();

  return outcomes.length ? (
    <div className="TableGroup">
      <Label color="blue" ribbon={true} className="TableGroup-label">
        {t(group)}
      </Label>
      <Table
        basic="very"
        size="small"
        compact="very"
        className="TableGroup-table"
        unstackable={true}
      >
        <Table.Body>
          {outcomes.map((outcome) => {
            const active =
              selectedOutcomes && selectedOutcomes.includes(outcome.name);
            return (
              <TableRow
                key={outcome.name}
                active={active}
                outcome={outcome}
                {...props}
              />
            );
          })}
        </Table.Body>
      </Table>
    </div>
  ) : null;
}

// TableGroup.propTypes = {
//   group: PropTypes.string.isRequired,
//   outcomes: PropTypes.arrayOf(outcomeShape).isRequired,
//   selectedOutcomes: PropTypes.arrayOf(PropTypes.string).isRequired
// };

export default TableGroup;
