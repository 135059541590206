import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { Dimmer, Loader } from "semantic-ui-react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageService from "../services/language.service";

const AuthContext = React.createContext([{}, () => {}]);

const authReducer = (state, action) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        authenticated: true,
      };

    case "logout":
      return {
        ...state,
        authenticated: false,
      };

    case "setUpdatingUser":
      return {
        ...state,
        userUpdating: action.payload,
      };

    case "setUserInformation":
      return {
        ...state,
        userInformation: action.payload,
        userUpdating: false,
      };

    case "setCurrentWorkspace":
      return {
        ...state,
        workspaceUpdating: false,
        currentWorkspace: action.payload,
      };

    case "setWorkspaceUpdating":
      return {
        ...state,
        workspaceUpdating: action.payload,
      };

    case "setHidePersonalWorkspaces":
      return {
        ...state,
        hidePersonalWorkspaces: action.payload,
      };

    default:
      console.log(`Warning, default case reached in AuthContext (action=${action.type})`);
      return state;
  }
};

const AuthContextProvider = (props) => {
  let location = useLocation();
  let history = useHistory();

  const initialAuthState = {
    authenticated: false,
    userInformation: null,
    hidePersonalWorkspaces: true,
    currentWorkspace: null,
    workspaceUpdating: false,
    userUpdating: false,
  };

  const [loading, setLoading] = useState(true);
  const [userInfoRetrievalFlag, setUserInfoRetrievalFlag] = useState(false);
  const { t, i18n } = useTranslation();

  const [authState, dispatchAuth] = React.useReducer(authReducer, initialAuthState);

  // Create a 401 axios interceptor, only on mount
  useEffect(() => {
    if (dispatchAuth) {
      AuthService.create401Interceptor(dispatchAuth);
    }
  }, [dispatchAuth]);

  // Capture token and languageId provided through SAML authentication workflow here, so that we can redirect in protected routes if not available
  useEffect(() => {
    const tokenParam = new URLSearchParams(location.search).get("token");
    let languageId = new URLSearchParams(location.search).get("languageId");

    if (languageId === null) {
      if (LanguageService.getPersistedLanguage()) {
        console.log(LanguageService.getPersistedLanguage());
        LanguageService.setLanguage(i18n, LanguageService.getPersistedLanguage());
      } else languageId = "en-US";
    } else {
      languageId = languageId.replace("_", "-");
    }

    console.log("getting token and language ID params");

    if (tokenParam) {
      AuthService.loginFromExistingToken(tokenParam, languageId);
      console.log("token found, logging in");
      LanguageService.setLanguage(i18n, languageId);
      dispatchAuth({ type: "login" });
      history.push(location.pathname);
      // history.goBack();
    }

    // let userData = JSON.parse(localStorage.getItem("user"));

    // if (userData && userData.languageId !== i18n.language) {
    //     `Current language (${i18n.language}) differs from storage (${userData.languageId}), setting to ${userData.languageId}`
    //   );
    //   i18n.changeLanguage(userData.languageId);
    // }
  }, [location]);

  // Set to loading if currently retrieving data, but not if data available / not trying to authenticate
  useEffect(() => {
    if (authState.authenticated && !authState.userInformation) setLoading(true);
    else setLoading(false);
  }, [authState]);

  // We check that that the AuthContext is sync'd up with local strage
  // This is used for example when the user reloads the page, and the store needs to be restored
  useEffect(() => {
    if (!authState.authenticated) {
      if (AuthService.tokenExistsAndValid()) {
        console.log("No auth context but valid token found --> creating context and retrieving user information");
        dispatchAuth({ type: "login" });
        setUserInfoRetrievalFlag(true);
        dispatchAuth({ type: "setUpdatingUser", payload: true });
        UserService.updateUserInformation().then((data) => {
          console.log("Done getting user info");
          dispatchAuth({ type: "setUserInformation", payload: data });
          setUserInfoRetrievalFlag(false);
        });
      } else {
        console.log("No auth context, will not be able to access protected routes");
      }
    } else {
      // we update user information if authenticated but information not available
      if (!authState.userInformation && !userInfoRetrievalFlag) {
        setUserInfoRetrievalFlag(true);
        console.log("Auth context, retrieving user information");
        dispatchAuth({ type: "setUpdatingUser", payload: true });
        UserService.updateUserInformation().then((data) => {
          dispatchAuth({ type: "setUserInformation", payload: data });
          setUserInfoRetrievalFlag(false);
        });
      } else {
        console.log("Auth context, and user information present --> fully authenticated");
      }
    }
  }, [authState]);

  if (loading)
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  else return <AuthContext.Provider value={[authState, dispatchAuth]}>{props.children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
