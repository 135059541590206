import React, { useState } from "react";
import { Icon, Button } from "semantic-ui-react";
import { json2csv } from "json-2-csv";
import { saveAs } from "file-saver";
import { parseDataForExport } from "../../utils/response_parsing";
import userService from "../../services/user.service";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";

const DataExportButton = ({ workspace_id, studiesData }) => {
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const { t, i18n } = useTranslation();

  // CSAI-106, CSAI-107, CSAI-108, CSAI-110 export data
  const exportData = (workspace_id) => {
    setLoadingExportButton(true);

    let json2csvCallback = function (err, csv) {
      if (err) throw err;
      // console.log(csv)
      saveAs(
        new Blob([csv], { type: "text/csv" }),
        `workspace_data_${workspace_id}.csv`
      );
      setLoadingExportButton(false);
    };

    userService
      .getWorkspaceStudies(workspace_id, 10000, 1, "created", "descending")
      .then((data) => {
        let dataForExport = parseDataForExport(data.items, studiesData);
        console.log(dataForExport);
        json2csv(dataForExport, json2csvCallback);
      });
  };

  return (
    <Button
      icon
      labelPosition="left"
      icon
      name="download"
      floated="right"
      icon="download"
      primary
      size="small"
      loading={loadingExportButton}
      onClick={() => {
        setLoadingExportButton(true);
        exportData(workspace_id);
      }}
    >
      <Icon name="download" />
      {capitalize(t("export workspace data"))}
    </Button>
  );
};

export default DataExportButton;
