import React, { useRef, useState, useEffect } from "react";
import { Grid, Rail, Sticky, Segment, Header, Button } from "semantic-ui-react";

import "./index.scss";

import { toggleItems, mapAnalysis } from "../../utils/analysis_helper";
import AnalysisGradability from "./Gradability";
import AnalysisTable from "./Table";
import AnalysisVisualisation from "./Visualisation";
import { capitalize } from "../../utils/misc";
import { useTranslation } from "react-i18next";

const DetailedAnalysisView = ({ rawAnalysis, static_token }) => {
  const { t, i18n } = useTranslation();
  const [selectedOutcomes, setSelectedOutcomes] = useState([]);
  const [dynamicThresholds, setDynamicThresholds] = useState({});
  const [analysis, setAnalysis] = useState();

  useEffect(() => {
    setSelectedOutcomes([]);
    setAnalysis(mapAnalysis(rawAnalysis));
  }, [rawAnalysis]);

  const contextRef = useRef();

  const handleChangeThreshold = (featureName, dynamicType, threshold) => {
    setDynamicThresholds({
      ...dynamicThresholds,
      [dynamicType]: dynamicThresholds[dynamicType] ? { ...dynamicThresholds[dynamicType], [featureName]: threshold } : { [featureName]: threshold },
    });
  };

  const handleToggleOutcome = (outcomeName) => {
    setSelectedOutcomes(toggleItems(selectedOutcomes, outcomeName));
  };

  console.log(rawAnalysis);

  if (analysis)
    return (
      <Segment className="Analysis" ref={contextRef}>
        <Header
          dividing={true}
          content={capitalize(t("expert view"))}
          icon="doctor"
          subheader={t("This view allows you to explore automated interpretation results.")}
        ></Header>

        <Grid width={12} padded="horizontally">
          <Grid.Column className="Analysis-table" mobile={12} tablet={6} computer={4} widescreen={4}>
            <AnalysisGradability
              outcomes={analysis.outcomes}
              exclude={["fundus_macula_gradability", "fundus_disc_gradability", "fundus_retina_gradability"]}
            />
            <AnalysisTable
              analysis={analysis}
              selectedOutcomes={selectedOutcomes}
              onToggleOutcome={handleToggleOutcome}
              onChangeThreshold={handleChangeThreshold}
            />
          </Grid.Column>
          <Grid.Column className="Analysis-visualisation" mobile={12} tablet={6} computer={8} widescreen={8}>
            {/* <Rail position="right" internal={true}>
              <Sticky context={contextRef} offset={20}> */}
            <AnalysisVisualisation
              analysis={analysis}
              dynamicThresholds={dynamicThresholds}
              selectedOutcomes={selectedOutcomes}
              interactive={true}
              static_token={static_token}
            />
            {/* </Sticky>
            </Rail> */}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  else return null;
};

export default DetailedAnalysisView;
