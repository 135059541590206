import React, { useCallback, useRef, useContext, useState } from "react";
import { Segment, Header, Button, Sticky, Icon } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import ProtocolPanel from "./ProtocolPanel";
import UploadControlButtons from "./UploadControlButtons";
import InputImagesPanel from "./InputImagesPanel";
import { AuthContext } from "../../contexts/AuthContext";
import NewStudyProgressModal from "./NewStudyProgressModal";
import uploadService from "../../services/upload.service";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";

const NewStudyForm = ({
  dispatchStudy,
  inputSpecs,
  validatedInputSpecs,
  validationErrors,
  studyState,
  possibleTags,
  startStudyUpload,
  metadataSpecs,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        const blob = new Blob([binaryStr], { type: file.type });

        dispatchStudy({
          type: "addUploadInput",
          item: {
            filename: file.name,
            imageDataURL: URL.createObjectURL(blob),
            imageFile: blob,
            analysisId: null,
            status: "uploading",
            gradable: null,
            verified: null,
            input_item_id: null,
            laterality: null,
            tags: [],
            uploadProgress: 0,
          },
        });

        uploadService
          .postQuality(authState.currentWorkspace.id, blob, file.name, dispatchStudy)
          .then((data) => {
            dispatchStudy({
              type: "updateUploadItemQA",
              filename: file.name,
              verified: data.verified,
              gradable: data.gradable,
              laterality: data.laterality,
              fov: data.fov,
              input_item_id: data.id,
            });
          })
          .catch((error) => {
            console.log("error!");
            dispatchStudy({
              type: "setAnalysisStatus",
              filename: file.name,
              status: "rejected",
            });
          });
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { t, i18n } = useTranslation();

  const [authState, authDispatch] = useContext(AuthContext);

  const contextRef = useRef(); // Used by sticky

  if (!authState.currentWorkspace) {
    return null;
  }

  return (
    <div ref={contextRef}>
      <Segment padded="very">
        <NewStudyProgressModal open={studyState.studyStatus} studyState={studyState} dispatchStudy={dispatchStudy} />

        <Header as="h1" dividing>
          <Icon name="clipboard outline" className="MainIcon" />
          <Header.Content>
            {capitalize(t("study"))}
            <Header.Subheader>
              {t("New study in Workspace")}: {authState.currentWorkspace.name}
            </Header.Subheader>
          </Header.Content>
        </Header>
        {/* <Sticky context={contextRef} offset={45}> */}
        <UploadControlButtons
          openFileUploadModal={open}
          validated={!validationErrors.length}
          startStudyUpload={startStudyUpload}
          studyState={studyState}
          inputFilesAdded={Boolean(studyState.uploadInputs.length)}
        />
        {/* </Sticky> */}

        <ProtocolPanel
          {...{
            inputSpecs,
            validatedInputSpecs,
            validationErrors,
            studyState,
            metadataSpecs,
            dispatchStudy,
            authState,
          }}
        />

        <InputImagesPanel
          {...{ getRootProps, getInputProps, isDragActive }}
          studyState={studyState}
          dispatchStudy={dispatchStudy}
          possibleTags={possibleTags}
          openFileModalFn={open}
        />
      </Segment>
    </div>
  );
};

export default NewStudyForm;
