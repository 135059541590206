import { t } from "i18next";
import React from "react";
import { Button, Grid, Segment } from "semantic-ui-react";

import StudyStatusMessage from "./StudyStatusMessage";
import { useTranslation } from "react-i18next";

const UploadControlButtons = ({
  openFileUploadModal,
  validated,
  startStudyUpload,
  inputFilesAdded,
  studyState,
}) => {

  const { t, i18n } = useTranslation();

  return (
    <Segment color={!validated ? "red" : "green"} raised>
      <Grid divided stackable>
        <Grid.Row>
          <Grid.Column width={3} textAlign={"center"} verticalAlign={"middle"}>
            <Button
              content={t("Start")}
              // icon="chevron right"
              primary={true}
              disabled={
                !validated ||
                studyState.uploadInputs.some((input) => !input.verified) ||
                studyState.metadata.some((metadata) => !metadata.validated) ||
                studyState.studyStatus === "started" ||
                studyState.studyStatus === "error"
              }
              onClick={startStudyUpload}
              size={"small"}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            <StudyStatusMessage
              inputFilesAdded={inputFilesAdded}
              validated={validated}
              studyState={studyState}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default UploadControlButtons;
