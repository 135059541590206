import React from "react";
import { Message, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const StudyStatusMessage = ({ validated, inputFilesAdded, studyState }) => {
  const { t, i18n } = useTranslation();

  if (!inputFilesAdded)
    return (
      <Message icon compact size="small">
        <Icon name="info circle" />
        <Message.Content>
          <Message.Header>{t("Start adding files")}</Message.Header>
          {t("Use the button or drag-and-drop in the area below")}
        </Message.Content>
      </Message>
    );
  else if (
    studyState.uploadInputs.some((input) => input.status === "uploading")
  )
    return (
      <Message icon compact size="small">
        <Icon name="spinner" loading />
        <Message.Content>
          <Message.Header>{t("Uploading and checking files")}</Message.Header>
          {t(
            "Your images are being uploaded to our server in order to check their quality"
          )}
        </Message.Content>
      </Message>
    );
  else if (
    !validated ||
    studyState.metadata.some((metadata) => !metadata.validated)
  )
    return (
      <Message icon compact size="small">
        <Icon name="warning" />
        <Message.Content>
          <Message.Header>{t("Unable to validate study")}</Message.Header>
          {t("Please see study protocol for more information")}
        </Message.Content>
      </Message>
    );
  // else if (studyState.studyStatus === "started")
  //   return (
  //     <Message icon compact size="small">
  //       <Icon name="spinner" loading />
  //       <Message.Content>
  //         <Message.Header>Study being processed</Message.Header>
  //         See individual images progress indicators
  //       </Message.Content>
  //     </Message>
  //   );
  else if (studyState.uploadInputs.some((input) => !input.verified))
    return (
      <Message icon error compact size="small">
        <Icon name="check" />
        <Message.Content>
          <Message.Header>
            {t("Study contains unverified images")}
          </Message.Header>
          {t(
            "Study contains images that could not be verified. Remove them from the study to continue."
          )}
        </Message.Content>
      </Message>
    );
  else if (studyState.uploadInputs.some((input) => !input.gradable))
    return (
      <Message icon warning compact size="small">
        <Icon name="check" />
        <Message.Content>
          <Message.Header>
            {t("Study contains ungradable images")}
          </Message.Header>
          {t(
            "You can still start automated interpretation, but results might not be reliable."
          )}
        </Message.Content>
      </Message>
    );
  else
    return (
      <Message icon compact size="small">
        <Icon name="check" />
        <Message.Content>
          <Message.Header>{t("Ready to go!")}</Message.Header>
          {t("Use the start button to submit analysis")}
        </Message.Content>
      </Message>
    );
};

export default StudyStatusMessage;
