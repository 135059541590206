import React, { useContext, Fragment } from "react";
import { Menu, Breadcrumb, Dropdown, Button, Image, Flag } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import logo from "../resources/Cybersight-AI-logo-reversed-rgb.png";

import { AuthContext } from "../contexts/AuthContext";

import { Link } from "react-router-dom";

import Logout from "./Logout";
import WorkspaceSelector from "./WorkspaceSelector";
import { capitalize } from "../utils/misc";

import LanguageService from "../services/language.service";

const Navbar = ({ currentUser }) => {
  const { t, i18n } = useTranslation();
  const [authState, authDispatch] = useContext(AuthContext);

  return (
    <Menu className="Navigation" fixed="top" inverted borderless>
      <Menu.Item>
        <Breadcrumb className="InstitutionBreadcrumb">
          <Link to={"/me"}>
            <Breadcrumb.Section
              as={Image}
              src={logo}
              compact
              size="small"
              style={{ marginBottom: "5px" }} // Used to avoid the navbar resizing when a workspace is picked
            />
          </Link>
          <Breadcrumb.Divider icon="right chevron" />
          {authState.authenticated && authState.currentWorkspace ? (
            <Fragment>
              <WorkspaceSelector />
              <Breadcrumb.Divider icon="right chevron" />
              <Button
                icon="add"
                size="tiny"
                circular
                inverted
                compact
                as={Link}
                to={`/ws/${authState.currentWorkspace.id}/new`}
                disabled={authState.currentWorkspace.archived}
              />
              <Button as={Link} to={`/ws/${authState.currentWorkspace.id}/`} icon="list alternate" size="tiny" circular inverted compact />
            </Fragment>
          ) : null}
        </Breadcrumb>
      </Menu.Item>

      {authState.authenticated ? (
        <Menu.Menu className="Navigation-menu" position="right">
          <Dropdown item text={capitalize(t("language"))}>
            <Dropdown.Menu>
              <Dropdown.Header>{capitalize(t("pick a language"))}</Dropdown.Header>
              <Dropdown.Item onClick={() => LanguageService.setLanguage(i18n, "en-US")}>
                <Flag name="uk" />
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => LanguageService.setLanguage(i18n, "fr-FR")}>
                <Flag name="fr" />
                Français
              </Dropdown.Item>
              <Dropdown.Item onClick={() => LanguageService.setLanguage(i18n, "vi-VN")}>
                <Flag name="vn" />
                Tiếng Việt
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item={true} text={capitalize(t("user"))}>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} icon="user circle" text={t("My Work")} to="/me" />
              {/* <Dropdown.Item
                as={Link}
                icon="info circle"
                text="Information"
                to="/information"
              />
              <Dropdown.Item
                as={Link}
                icon="phone"
                text="Contact"
                to="/contact"
              /> */}
              <Logout />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      ) : (
        <Menu.Item as={Link} icon="phone" content="Contact" to="/contact" position="right" />
      )}
    </Menu>
  );
};

export default Navbar;
