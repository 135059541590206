import React, { Fragment, useState, useContext, useEffect } from "react";
import { Table, Icon, Segment, Header } from "semantic-ui-react";
import { format } from "date-fns";
import { AuthContext } from "../../contexts/AuthContext";
import userService from "../../services/user.service";
import { useHistory, Link } from "react-router-dom";
import StudyPagination from "../Workspace/StudyTable/StudyPagination";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";

const UserPageStudies = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [authState, authDispatch] = useContext(AuthContext);
  const [studiesData, setStudiesData] = useState(null);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);
  const [paginationPerPageLimit, setPaginationPerPageLimit] = useState(15);
  const [paginationSorting, setPaginationSorting] = useState({
    column: "created",
    direction: "descending",
  });
  const [paginationMetadataFilter, setPaginationMetadataFilter] = useState(
    null
  );

  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const history = useHistory();
  const sortableColumns = ["created"];

  useEffect(() => {
    setLoading(true);
    userService
      .getUserStudies(
        paginationPerPageLimit,
        paginationCurrentPage,
        paginationSorting.column,
        paginationSorting.direction,
        paginationMetadataFilter
      )
      .then((data) => {
        setStudiesData(data);
        setLoading(false);
      });
  }, [
    authState,
    paginationCurrentPage,
    paginationSorting,
    paginationMetadataFilter,
    i18n.language,
  ]);

  useEffect(() => {
    if (studiesData) {
      let headers = [
        {
          icon: "desktop",
          text: t("workspace_other"),
          sorting_id: "workspace",
        },
        {
          icon: "info circle",
          text: t("Creation Date"),
          sorting_id: "created",
        },
        {
          icon: "user outline",
          text: t("Status"),
          sorting_id: "status",
        },
      ];

      setTableHeaders(headers);

      generateRows();
    }
  }, [studiesData]);

  const generateRows = () => {
    let rows = [];

    studiesData.items.forEach((study) => {
      rows.push(
        <Table.Row
          key={`row-${study.id}`}
          onClick={() => history.push(`/ws/${study.workspace.id}/${study.id}`)}
        >
          <Table.Cell>
            {format(new Date(study.created), "yyyy-MM-dd HH:mm")}
          </Table.Cell>
          <Table.Cell>{study.status}</Table.Cell>
          <Table.Cell>{study.created_by.email}</Table.Cell>
        </Table.Row>
      );
    });
    setTableRows(rows);
  };

  return (
    <Fragment>
      <Segment padded raised loading={loading}>
        <Header
          dividing={true}
          content={capitalize(t("study_other"))}
          icon="clipboard outline"
          subheader={t("List of studies created by the user.")}
        />
        <Table selectable celled stackable compact sortable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="4" style={{ cursor: "default" }}>
                <StudyPagination
                  studies_paginated={studiesData}
                  setPaginationCurrentPage={setPaginationCurrentPage}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Header>
            <Table.Row>
              {tableHeaders.map((header) => (
                <Table.HeaderCell
                  key={header.text}
                  onClick={() => {
                    if (sortableColumns.includes(header.sorting_id)) {
                      setPaginationSorting({
                        column: header.sorting_id,
                        direction:
                          paginationSorting.column === header.sorting_id
                            ? paginationSorting.direction === "ascending"
                              ? "descending"
                              : "ascending"
                            : "descending",
                      });
                    }
                  }}
                  sorted={
                    paginationSorting.column === header.sorting_id
                      ? paginationSorting.direction
                      : null
                  }
                >
                  <Icon name={header.icon} />
                  {header.text}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {studiesData
              ? studiesData.items.map((study) => (
                <Table.Row key={study.id}>
                  <Table.Cell selectable>
                    <Link to={`/ws/${study.workspace.id}/${study.id}`}>
                      {/* <a href={`/ws/${study.workspace.id}/${study.id}`}> */}
                      {study.workspace.name}
                      {/* </a> */}
                    </Link>
                  </Table.Cell>
                  <Table.Cell selectable>
                    <Link to={`/ws/${study.workspace.id}/${study.id}`}>
                      {format(new Date(study.created), "yyyy-MM-dd HH:mm")}
                    </Link>
                  </Table.Cell>
                  <Table.Cell selectable>
                    <Link to={`/ws/${study.workspace.id}/${study.id}`}>
                      {study.status}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))
              : null}
          </Table.Body>
        </Table>
      </Segment>
    </Fragment>
  );
};

export default UserPageStudies;
