import io from "socket.io-client";

import { API_URL } from "../utils/get_env";

const socket = io(API_URL, { path: "/wss/socket.io" });

const subscribeToStudy = (study_id) => {
  socket.on(`analysis-update-${study_id}`, (data) => {});
  socket.emit("subscribe", { study: study_id });
};

const subscribeToStudyUpdates = (study_id, dispatchStudy) => {
  socket.on(`study-update-${study_id}`, (data) => {
    let data_json = JSON.parse(data);

    console.log("STUDY UPDATE!!!");
    console.log(data_json);

    dispatchStudy({
      type: "updateStudyObject",
      study: data_json,
    });

    if (data_json.status === "done" || data_json.status === "error")
      unsubscribeStudy(study_id);
  });
  socket.emit("subscribe", { study: study_id });
};

const unsubscribeStudy = (study_id) => {
  socket.emit("unsubscribe", { study: study_id });
  socket.removeAllListeners(`analysis-study-${study_id}`);
};

const subscribeToAnalysis = (analysis_id, filename, dispatchStudy) => {
  socket.on(`analysis-update-${analysis_id}`, (data) => {
    let data_json = JSON.parse(data);

    dispatchStudy({
      type: "setAnalysisStatus",
      filename: filename,
      status: data_json.status,
    });

    if (data_json.status === "done" || data_json.status === "error")
      unsubscribeAnalysis(analysis_id);
  });
  socket.emit("subscribe", { analysis: analysis_id });
};

const subscribeToAnalysisFromId = (analysis_id, dispatchStudy) => {
  socket.on(`analysis-update-${analysis_id}`, (data) => {
    let data_json = JSON.parse(data);

    dispatchStudy({
      type: "setAnalysis",
      id: analysis_id,
      analysis: data_json,
    });

    if (data_json.status === "done" || data_json.status === "error")
      unsubscribeAnalysis(analysis_id);
  });
  socket.emit("subscribe", { analysis: analysis_id });
};

const unsubscribeAnalysis = (analysis_id) => {
  socket.emit("unsubscribe", { analysis: analysis_id });
  socket.removeAllListeners(`analysis-update-${analysis_id}`);
};

export {
  subscribeToStudy,
  subscribeToAnalysis,
  subscribeToAnalysisFromId,
  subscribeToStudyUpdates,
  unsubscribeStudy,
  unsubscribeAnalysis,
};
