import React, { Fragment, useEffect } from "react";
import { Form, Dropdown, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const MetadataInput = ({ metadata, studyState, dispatchStudy }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatchStudy({
      type: "updateMetadata",
      name: metadata.name,
      validated: metadata.optional,
      content: "",
    });
  }, []);

  const handleChange = (event, component) => {
    let content = component.value;

    let validated = false;

    switch (metadata.input_type) {
      case "value-select":
        if (content.length > 0) validated = true;
        else validated = false;
        break;

      default:
      case "text":
        if (!metadata.optional) {
          let regex = new RegExp(metadata.validation_regex);
          validated = regex.test(content);
        } else validated = true;
        break;
    }

    // We only dispatch an update if the validated status changed
    // OR if still validated but content differs (to pickup changes once validated)
    if (
      validated !== metadata.validated ||
      (validated && content !== metadata.content)
    ) {
      dispatchStudy({
        type: "updateMetadata",
        name: metadata.name,
        validated: validated,
        content: content,
      });
    }
  };

  const getInputForm = () => {
    switch (metadata.input_type) {
      case "value-select":
        let metadata_values = metadata.available_values.map((item) => {
          return {
            key: item,
            text: item,
            value: item,
          };
        });

        return (
          <Dropdown
            error={!metadata.validated}
            clearable
            search
            options={metadata_values}
            selection
            // placeholder={
            //   studies_paginated.sortable_metadata[
            //     Object.keys(studies_paginated.sortable_metadata)[0]
            //   ].info.name_verbose
            // }
            onChange={(event, component) => {
              handleChange(event, component);
            }}
          />
        );

      default:
      case "text":
        return (
          <Form.Input
            error={!metadata.validated}
            icon="address card outline"
            iconPosition="left"
            labelPosition="left"
            size="small"
            placeholder={t(metadata.name_verbose)}
            required={!metadata.optional}
            onChange={handleChange}
            width={4}
          ></Form.Input>
        );
    }
  };

  return (
    <Fragment>
      <List divided relaxed>
        <List.Item>
          {metadata.validated ? (
            <List.Icon color="green" name="check circle outline" />
          ) : (
            <List.Icon name="circle outline" />
          )}
          <List.Content>
            <List.Header content={t(metadata.name_verbose)} />
            <br />
            <Form.Group>{getInputForm()}</Form.Group>
            <List.Description>{t(metadata.description)}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </Fragment>
  );
};

export default MetadataInput;
