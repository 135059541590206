import React, { useState, Fragment, useContext, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

const WorkspaceSelector = () => {
  const [authState, authDispatch] = useContext(AuthContext);

  let history = useHistory();

  if (authState.currentWorkspace) {
    return (
      <Fragment>
        <Dropdown labeled text={authState.currentWorkspace.name}>
          <Dropdown.Menu>
            <Dropdown.Header content={authState.currentWorkspace.name} />
            <Dropdown.Item as={Link} to={`/ws/${authState.currentWorkspace.id}/`} icon="list alternate" content="Study List" />
            <Dropdown.Item
              as={Link}
              to={`/ws/${authState.currentWorkspace.id}/new`}
              icon="add circle"
              content="New Study"
              disabled={authState.currentWorkspace.archived}
            />
            <Dropdown.Divider />
            <Dropdown.Header content="Switch Workspace" />
            {authState.userInformation.workspaces.map((workspace) => (
              <Dropdown.Item
                key={workspace.id}
                onClick={() => {
                  history.push(`/ws/${workspace.id}/`);
                }}
              >
                {workspace.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  } else return "";
};

export default WorkspaceSelector;
