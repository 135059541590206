import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Icon,
  Modal,
  Progress,
  Message,
  Segment,
} from "semantic-ui-react";
import { Link, useParams, useHistory } from "react-router-dom";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../utils/misc";

const NewStudyProgressModal = ({ open, studyState, dispatchStudy }) => {
  let { workspace_id } = useParams();
  let history = useHistory();

  const { t, i18n } = useTranslation();

  const [uploadCompletion, setUploadCompletion] = useState({
    value: 0,
    total: 0,
  });
  const [processingCompletion, setProcessingCompletion] = useState({
    value: 0,
    total: 0,
  });

  const [processingStarted, setProcessingStarted] = useState({
    value: 0,
    total: 0,
  });

  const [uploadStarted, setUploadStarted] = useState({
    value: 0,
    total: 0,
  });

  useEffect(() => {
    let uploadCompleted = 0;
    let processingCompleted = 0;
    let processingStarted_ = 0;
    let uploadStarted_ = 0;

    studyState.uploadInputs.forEach((upload) => {
      if (
        upload.status === "uploading" ||
        upload.status === "queued" ||
        upload.status === "processing" ||
        upload.status === "done" ||
        upload.status === "error"
      )
        uploadStarted_ += 1;

      if (
        upload.status === "queued" ||
        upload.status === "processing" ||
        upload.status === "done" ||
        upload.status === "error"
      )
        uploadCompleted += 1;

      if (
        upload.status === "processing" ||
        upload.status === "done" ||
        upload.status === "error"
      )
        processingStarted_ += 1;

      if (upload.status === "done" || upload.status === "error")
        processingCompleted += 1;
    });

    setUploadCompletion({
      value: uploadCompleted,
      total: studyState.uploadInputs.length,
    });
    setProcessingCompletion({
      value: processingCompleted,
      total: studyState.uploadInputs.length,
    });
    setProcessingStarted({
      value: processingStarted_,
      total: studyState.uploadInputs.length,
    });
    setUploadStarted({
      value: uploadStarted_,
      total: studyState.uploadInputs.length,
    });
  }, [studyState]);

  const CountdownButton = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Button
          color="green"
          as={Link}
          to={`/ws/${workspace_id}/${studyState.studyId}/`}
        >
          Proceed to Report Page
        </Button>
      );
    } else {
      // Render a countdown
      return (
        <Button
          color="green"
          as={Link}
          to={`/ws/${workspace_id}/${studyState.studyId}/`}
        >
          Proceed to Report Page ({seconds})
        </Button>
      );
    }
  };

  const getModalContent = (status) => {
    switch (status) {
      case "started":
        return (
          <Fragment>
            {/* <Progress
              value={uploadStarted.value}
              total={uploadStarted.total}
              active={uploadCompletion.value !== uploadCompletion.total}
              sucess={uploadCompletion.value === uploadCompletion.total}
              progress="ratio"
              color="teal"
            >
              <Icon name="upload" />
              <Fragment>Data Upload</Fragment>
            </Progress> */}

            <Progress
              value={processingStarted.value}
              total={processingStarted.total}
              active={processingCompletion.value !== processingCompletion.total}
              success={
                processingCompletion.value === processingCompletion.total
              }
              progress="ratio"
              color="blue"
            >
              <Icon name="microchip" />
              <Fragment>{t("AI Automated Interpretation")}</Fragment>
            </Progress>
            {studyState.slow && (
              <Message icon warning>
                <Icon name="time" />
                <Message.Content>
                  <Message.Header>
                    {t("Automated interpretation taking longer than expected")}
                  </Message.Header>
                  {t("This could be due to heavy concurrent use of the Cybersight AI platform.")}
                  {t("Please contact")}
                  <a href="mailto:support@cybersight.org">
                    support@cybersight.org
                  </a>{" "}
                  {t("if this persists.")}
                </Message.Content>
              </Message>
            )}
          </Fragment>
        );

      case "done":
        return (
          <Fragment>
            <Message icon positive>
              <Icon name="check" />
              <Message.Content>
                <Message.Header>
                  {t("Automated interpretation complete")}
                </Message.Header>
                {t("You will be automatically redirected to the report page in 5 seconds, or click the button below to go there now.")}
              </Message.Content>
            </Message>
            <Segment basic textAlign="center">
              <Countdown date={Date.now() + 5000} renderer={CountdownButton} />
            </Segment>
          </Fragment>
        );

      case "done-error":
        return (
          <Fragment>
            <Message icon warning>
              <Icon name="warning" />
              <Message.Content>
                <Message.Header>
                  Automated interpretation complete but error(s) encountered
                </Message.Header>
                One or more errors were encountered during study processing. You
                will be redirected to the study report page in 5 seconds, where
                you can find out more about the error. Please contact{" "}
                <a href="mailto:support@cybersight.org">
                  support@cybersight.org
                </a>{" "}
                if this persists.
              </Message.Content>
            </Message>
            <Segment basic textAlign="center">
              <Countdown date={Date.now() + 5000} renderer={CountdownButton} />
            </Segment>
          </Fragment>
        );

      case "error-upload":
        return (
          <Fragment>
            <Message icon error>
              <Icon name="warning circle" />
              <Message.Content>
                <Message.Header>Error uploading data</Message.Header>
                There was an error uploading data for automated interpretation.
                Click the button below to try again. Please contact{" "}
                <a href="mailto:support@cybersight.org">
                  support@cybersight.org
                </a>{" "}
                if this persists.
              </Message.Content>
            </Message>
            <Segment basic textAlign="center">
              <Button
                color="red"
                onClick={() => {
                  dispatchStudy({
                    type: "reset",
                  });
                }}
              >
                Back to study creation
              </Button>
            </Segment>
          </Fragment>
        );
    }
  };

  return (
    <Modal dimmer="blurring" size="small" open={open} onClose={() => { }}>
      <Modal.Header>{capitalize(t("automated interpretation progress"))}</Modal.Header>
      <Modal.Content>{getModalContent(studyState.studyStatus)}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          small
          disabled={
            uploadCompletion.value === uploadCompletion.total ||
            studyState.studyStatus === "error-upload"
          }
          onClick={() => {
            history.push(`/ws/${workspace_id}/`);
          }}
        >
          Cancel Upload
        </Button>
        <Button
          primary
          small
          disabled={
            uploadCompletion.value !== uploadCompletion.total &&
            studyState.studyStatus !== "error-upload"
          }
          onClick={() => {
            history.push(`/ws/${workspace_id}/`);
          }}
        >
          Workspace Home
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewStudyProgressModal;
