import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../../utils/misc";

import GradientIcon from "./GradientIcon";

const OutcomeLabel = ({ color, id, index, labels, ...props }) => {

  const { t, i18n } = useTranslation();

  const getIcon = () => {
    switch (id) {
      case "gender_score":
      case "gender_classification_score":
        return ["mars", "venus"][index];
      case "fundus_overall_gradability_score":
      case "fundus_macula_gradability_score":
      case "fundus_disc_gradability_score":
      case "fundus_retina_gradability_score":
      case "oct_quality_score":
      case "fundus_verification_score":
      case "oct_verification_score":
        return (
          <GradientIcon
            name={["check", "times"][index]}
            index={index}
            length={labels.length}
          />
        );
      default:
        return (
          <GradientIcon
            name="circle"
            index={index}
            length={labels.length}
          />
        );
    }
  }

  const customProps = {
    icon: getIcon(),
    content: t(labels[index]),
    ...props,
  };

  return <Label {...customProps} />;
}

OutcomeLabel.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OutcomeLabel;
