import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import { getGradientColor } from "../../../utils/analysis_helper";

class GradientIcon extends PureComponent {
  render() {
    const { index, length, ...props } = this.props,
      color = getGradientColor(index / (length - 1));
    return <Icon color={color} {...props} />;
  }
}

GradientIcon.propTypes = {
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default GradientIcon;
