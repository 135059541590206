import i18n from "../i18n";
import { capitalize } from "../utils/misc";

console.log(i18n.t("Macula-centered"))

//TODO: Provide this through API?
const possibleTags = [
  {
    name: "eye",
    text: "Eye",
    type: "radio",
    values: [
      {
        text: i18n.t("Left Eye"),
        value: "left-eye",
      },
      {
        text: i18n.t("Right Eye"),
        value: "right-eye",
      },
    ],
  },
  {
    name: "fov",
    text: "FoV",
    type: "radio",
    values: [
      {
        text: i18n.t("Macula-centered"),
        value: "macula-centered",
      },
      {
        text: i18n.t("Disc-centered"),
        value: "disc-centered",
      },
    ],
  },
];

const parseTagsFromAPI = (tagsFromAPI, possibleTags) => {
  let parsedTags = [];
  possibleTags.forEach((tag) => {
    tag.values.forEach((tagValue) => {
      if (tagsFromAPI.includes(tagValue.value)) {
        parsedTags.push({
          name: tag.name,
          name_text: tag.text,
          value: tagValue.value,
          value_text: tagValue.text,
        });
      }
    });
  });
  return parsedTags;
};

const tagsToAPIStr = (tags) => {
  let tagStr = "";

  let delim;

  Object.keys(tags).forEach((tag) => {
    if (tagStr.length === 0) delim = "";
    else delim = ",";

    if (tags[tag] !== null) tagStr = tagStr.concat(delim, tags[tag]);
  });

  return tagStr;
};

export default {
  possibleTags,
  parseTagsFromAPI,
  tagsToAPIStr,
};
