import _ from "lodash";

export const parseDataForExport = (paginatedStudies, studiesData) => {
  const fieldsToExport = ["created", "created_by", "status", "gradability", "outcome"];

  let dataToExport = paginatedStudies.map((item) => {
    let item_to_export = {};

    // Parse AI outcome
    item_to_export.outcome = parseVerboseOutcome(item);

    // Parse Labelbox outcome
    if ("labelbox-report" in studiesData.reporting_integrations && studiesData.reporting_integrations["labelbox-report"].enabled) {
      if (!fieldsToExport.includes("labelbox")) fieldsToExport.push("labelbox");
      item_to_export.labelbox = parseLabelboxIntegration(item, studiesData);
    }

    // Parse Metadata
    item.study_metadata.map((metadata) => {
      if (!fieldsToExport.includes(metadata.name)) fieldsToExport.push(metadata.name);
      item_to_export[metadata.name] = metadata.value;
    });

    item_to_export.created_by = item.created_by.email;
    item_to_export.created = item.created;
    item_to_export.status = item.status;

    if (item.overviews.filter((item) => item.name === "gradability").length)
      item_to_export.gradability = item.overviews.filter((item) => item.name === "gradability")[0].value;

    return item_to_export;
  });

  return _.map(dataToExport, (row) => _.pick(row, fieldsToExport));
};

export const parseVerboseOutcome = (study) => {
  if (study.verbose_outcomes.length && study.status === "done") {
    let outcome = "Normal";

    study.verbose_outcomes.forEach((verbose_outcome) => {
      if (verbose_outcome.urgency !== "normal") outcome = "Refer";
    });

    return outcome;
  } else return null;
};

export const parseLabelboxIntegration = (study, studiesData) => {
  if (studiesData && "labelbox-report" in studiesData.reporting_integrations && studiesData.reporting_integrations["labelbox-report"].enabled) {
    const labelboxData = _.find(study.integration_data, (datum) => datum.name === "labelbox-report");

    if (labelboxData && studiesData && study.status === "done") {
      switch (labelboxData.status) {
        case "available":
          return labelboxData.data.outcome;

        case "error":
          return "ERROR";

        case "unavailable":
          return "N/A";

        case "incomplete":
          return "INCOMPLETE";

        case "not-started":
          return "NOT STARTED";

        default:
          return null;
      }
    } else return null;
  } else return null;
};
